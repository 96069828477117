import { zo, vehicleDetailsSchema, licensePlateSchemaObject } from 'remote/global';

const makeOfferVehicleSchema = vehicleDetailsSchema
  .pick({
    licensePlate: true,
    make: true,
    model: true,
    year: true,
  })
  .extend({
    seats: zo.coerce.string().min(1).pipe(zo.coerce.number().int()), // We do min and max validation afterwards
  });

const acceptOperatorOfferRequestSchema = zo
  .object({
    newVehicle: makeOfferVehicleSchema.or(zo.null()),
    contactEmail: zo.string().min(1).email(),
    newPriceExclVat: zo.coerce.number().min(0),
    uuid: zo.string(),
    vehicleId: zo.number().optional().nullable(),
    termsAccepted: zo.literal(true),
    pickExistingVehicle: zo.boolean().optional(),
    licensePlate: licensePlateSchemaObject.nullable(),
    minPassengerCount: zo.number(),
    maxPassengerCount: zo.number(),
  })
  .superRefine((args, ctx) => {
    const { minPassengerCount, maxPassengerCount, newVehicle, vehicleId, pickExistingVehicle } =
      args;

    if (newVehicle != null) {
      const passengerCount = newVehicle.seats;

      // Checks if passenger count is within bounds of maxPassengerCount
      if (passengerCount > maxPassengerCount)
        ctx.addIssue({
          code: zo.ZodIssueCode.too_big,
          maximum: maxPassengerCount!,
          inclusive: true,
          type: 'number',
          path: ['newVehicle.seats'],
        });
      if (passengerCount < minPassengerCount)
        ctx.addIssue({
          code: zo.ZodIssueCode.too_small,
          minimum: minPassengerCount!,
          inclusive: true,
          type: 'number',
          path: ['newVehicle.seats'],
        });
    }

    if (pickExistingVehicle) {
      if (vehicleId == null)
        ctx.addIssue({
          code: zo.ZodIssueCode.custom,
          params: { i18n: 'required' },
          path: ['licensePlate'],
        });
    } else if (newVehicle?.licensePlate == null) {
      ctx.addIssue({
        code: zo.ZodIssueCode.custom,
        params: { i18n: 'required' },
        path: ['newVehicle.licensePlate'],
      });
    }
  });

type AcceptOperatorOfferRequestSchemaType = zo.infer<typeof acceptOperatorOfferRequestSchema>;
type MakeOfferVehicleSchemaType = zo.infer<typeof makeOfferVehicleSchema>;

export { acceptOperatorOfferRequestSchema };
export type { AcceptOperatorOfferRequestSchemaType, MakeOfferVehicleSchemaType };
