import { useParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { PageLoaderFullScreen, PaymentMethod } from 'remote/global';
import { EditOrderPaymentStatusEnum } from '@/utils/data-types';
import { useOrdersPaymentStatusQuery } from '@/api/queries/orders';

function EditBookingRedirectView() {
  const { bookingNumber, uuid } = useParams();
  const navigate = useNavigate();
  const paymentStatus = useOrdersPaymentStatusQuery(bookingNumber!, uuid!);

  useEffect(() => {
    (async () => {
      if (paymentStatus?.data === EditOrderPaymentStatusEnum.INITIALIZED) {
        return;
      }
      navigate(`/change-booking-details/${bookingNumber}/changed?type=${PaymentMethod.PREPAID}`, {
        replace: true,
      });
    })();
  }, [navigate, paymentStatus?.data, bookingNumber]);

  return <PageLoaderFullScreen />;
}

export default EditBookingRedirectView;
