import { OfferSchemaType } from 'remote/global';
import { UseTranslationResponse } from 'react-i18next';

export const isLocal = window.MOBIFER_ENV === 'local';
export const isStaging = window.MOBIFER_ENV === 'dev';
export const isDev = isLocal || isStaging;
export const isProd = window.MOBIFER_ENV === 'prod';
const isTestRunner = window.Cypress;
export const isAnalyticsEnabled = (isStaging || isProd) && !isTestRunner;

interface Section {
  title: string;
  content: string[];
}

const parseParagraphs = (text: string): Section[] => {
  return text
    .split(/\r?\n--+\s*\r?\n*/)
    .filter(Boolean)
    .map((section) => {
      const parts = section.split(/\r?\n/).filter(Boolean);
      const title = parts.shift() || '';
      const content = parts;
      return { title, content };
    });
};

// Creates a url with a https prefix if it does not exist in the string
// If the url is already prefixed with either http or https, it will use the one provided
const createUrlWithProtocol = (url: string) =>
  url.replace(/^(?:(.*:)?\/\/)?(.*)/i, (match, schema, _url) =>
    schema ? match : `https://${_url}`
  );

// eslint-disable-next-line consistent-return
const getUrlByProject = (project: 'landing' | 'operator' | 'admin' | 'corporate') => {
  switch (project) {
    case 'landing':
      if (isDev) return `https://landing.dev.mobifer.com`;
      return `https://mobifer.com`;
    case 'operator':
      if (isDev) return `https://operator.dev.mobifer.com`;
      return `https://operator.mobifer.com`;
    case 'admin':
      if (isDev) return `https://admin.dev.mobifer.com`;
      return `https://admin.mobifer.com`;
    case 'corporate':
      if (isDev) return `https://corporate.dev.mobifer.com`;
      return `https://corporate.mobifer.com`;
    default:
  }
};

const getOfferTripByUuid = (offers: OfferSchemaType | undefined, uuid: string) =>
  offers?.trips
    ?.flatMap((trip) => trip.operatorOffers.map((offer) => ({ trip, offer })))
    ?.find(({ offer }) => offer.uuid === uuid) || null;

async function runSequentially<T>(promiseFuncs: (() => Promise<T>)[]) {
  return promiseFuncs.reduce(async (prevPromise, currPromiseFunc) => {
    await prevPromise;
    await currPromiseFunc();
  }, Promise.resolve());
}

function makeTranslationArray<T>(
  key: string,
  paths: (keyof T)[],
  tInstance: UseTranslationResponse<string>
) {
  const arr: T[] = [];
  let index = 0;

  const processPath = (path: keyof T, pathIndex: number) => {
    const pathKey = `${key}[${pathIndex}].${String(path)}`;
    const exists = tInstance.i18n.exists(pathKey);

    if (exists) {
      const value = tInstance.t(pathKey);

      // Check if '_links' exist and add them as an array of strings
      const links: string[] = [];
      let linkIndex = 0;

      // Iterate over the possibility of having '_links'
      let linkExists = true;
      while (linkExists) {
        const linkKey = `${pathKey}_links[${linkIndex}]`;

        // Check if any path exists for the current index
        linkExists = tInstance.i18n.exists(linkKey);
        if (!linkExists) break;

        links.push(tInstance.t(linkKey));
        linkIndex += 1;
      }

      return { [path]: value, ...(links.length > 0 ? { [`${String(path)}_links`]: links } : {}) };
    }

    return null;
  };

  // While loop with a condition based on the presence of any key in the current index
  let hasNext = true;
  while (hasNext) {
    const currentIndex = index; // Capture current index in a closure
    const baseKey = `${key}[${currentIndex}]`;

    // Check if any path exists for the current index
    hasNext = paths.some((path) => tInstance.i18n.exists(`${baseKey}.${String(path)}`));
    if (!hasNext) break;

    // Ensure the array has an object for the current index
    if (!arr[currentIndex]) {
      arr[currentIndex] = {} as any;
    }

    paths.forEach((path) => {
      const result = processPath(path, currentIndex);
      if (result) {
        arr[currentIndex] = { ...arr[currentIndex], ...result };
      }
    });

    index += 1;
  }

  return arr;
}

export type { Section };

export {
  parseParagraphs,
  createUrlWithProtocol,
  getUrlByProject,
  getOfferTripByUuid,
  runSequentially,
  makeTranslationArray,
};
