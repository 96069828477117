import { useState } from 'react';
import { Box } from '@mui/system';
import { Paper } from '@mui/material';
import { CustomerOfferSchemaType, OfferList } from 'remote/global';
import CreateRideOffersForm from 'components/forms/CreateRideOffersForm/CreateRideOffersForm';
import AppMenu from 'components/AppMenu';

function InternalTestingView() {
  const [offers, setOffers] = useState<CustomerOfferSchemaType | undefined>();

  return (
    <>
      <AppMenu />
      <Box px={1} py={2} maxWidth='lg' mx='auto'>
        <Paper elevation={3} sx={{ p: [2, 3], my: [2, 4] }}>
          <CreateRideOffersForm
            handleInternalTestingCallback={(result) => {
              // Makes sure that offers are cleared before a new response comes in so that the OfferList component can rerender with new values
              setOffers(undefined);
              // Hack to call setOffers in a separate tick to make sure 'undefined' gets called before setOffers(result)
              setTimeout(() => {
                setOffers(result);
              }, 1);
            }}
          />
        </Paper>
        {offers != null && (
          <Box mb={[8, 12, 16]}>
            <OfferList
              defaultValues={offers}
              originalOperators={[]}
              isLoadingOffers={false}
              handleSubmitFilters={() => {}}
            />
          </Box>
        )}
      </Box>
    </>
  );
}

export default InternalTestingView;
