import { useTheme } from '@mui/material/styles';
import { Typography, Grid, useMediaQuery, Paper, Button, List, Box } from '@mui/material';
import { colors } from 'remote/global';
import { useTranslation } from 'react-i18next';
import busIcon from 'assets/bus-icon.svg';
import userIcon from 'assets/user-icon.svg';
import mobiferLogoElement1 from 'assets/mobifer-logo-element-1.svg';
import mobiferLogoElement2 from 'assets/mobifer-logo-element-2.svg';
import { isDev, makeTranslationArray } from 'utils/helpers';
import OperatorCTAListItems from './OperatorCTAListItems';

type BenefitItem = { title: string; description: string };

const benefitsIcons = [busIcon, userIcon];
function HeroSection() {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const tInstance = useTranslation();
  const { t } = tInstance;

  const benefits: BenefitItem[] = makeTranslationArray<BenefitItem>(
    'BecomeOperatorView.benefits',
    ['title', 'description'],
    tInstance
  );

  const renderEnv = () => {
    if (isDev) {
      return `https://operator.dev.mobifer.com/sign-up`;
    }
    return `https://operator.mobifer.com/sign-up`;
  };

  return (
    <Grid
      container
      justifyContent='center'
      sx={{ background: colors.tiffanyBlue5, padding: `94px ${smallScreen ? '16px' : 0}` }}
    >
      <Box
        component='img'
        src={mobiferLogoElement1}
        alt=''
        sx={{
          position: 'absolute',
          top: 100,
          right: 0,
          userSelect: 'none',
          pointerEvents: 'none',
          zIndex: -1,
        }}
      />
      <Box
        component='img'
        src={mobiferLogoElement2}
        alt=''
        sx={{
          position: 'absolute',
          left: 0,
          bottom: 100,
          userSelect: 'none',
          pointerEvents: 'none',
          zIndex: -1,
        }}
      />
      <Grid container item maxWidth='xl'>
        <Grid
          item
          md={6}
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: smallScreen ? '24px' : 0,
          }}
        >
          <Box sx={{ width: '100%', maxWidth: '525px' }}>
            <Typography
              component='h1'
              sx={{
                color: colors.primary,
                fontWeight: 700,
                fontSize: smallScreen ? '36px' : '48px',
                lineHeight: smallScreen ? '44px' : '60px',
                marginBottom: '24px',
              }}
            >
              {t('BecomeOperatorView.title')}
            </Typography>
            <Typography
              component='h2'
              sx={{
                color: colors.primary,
                fontWeight: 400,
                fontSize: '20px',
              }}
            >
              {t('BecomeOperatorView.subtitle')}
            </Typography>
            {Array.isArray(benefits) &&
              benefits.map((benefit, idx) => {
                const icon = benefitsIcons[idx];
                return (
                  <>
                    <Typography
                      sx={{
                        color: colors.tiffanyBlue,
                        fontSize: ['20px', undefined, '24px'],
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: 1.5,
                        fontWeight: 600,
                        mt: '2rem',
                        mb: '1rem',
                      }}
                    >
                      <img src={icon} alt='' /> {benefit.title}
                    </Typography>
                    <Typography sx={{ mb: '2rem', pl: ['1.5rem', undefined, '3.25rem'] }}>
                      {benefit.description}
                    </Typography>
                  </>
                );
              })}
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Paper
            elevation={1}
            sx={{
              border: `1px solid ${colors.tiffanyBlue3}`,
              borderRadius: '16px',
              padding: '40px 16px',
              width: '100%',
              maxWidth: '385px',
            }}
          >
            <Typography
              component='h3'
              mb='8px'
              sx={{
                color: colors.primary,
                fontWeight: 600,
                fontSize: '48px',
                lineHeight: '60px',
                textAlign: 'center',
              }}
            >
              {t('BecomeOperatorView.freeTrial.title')}
            </Typography>
            <Typography
              mb='16px'
              sx={{
                color: colors.gray1,
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '24px',
                textAlign: 'center',
              }}
            >
              {t('BecomeOperatorView.freeTrial.description')}
            </Typography>
            <Button
              onClick={() => {
                window.location.href = renderEnv();
              }}
              fullWidth
              sx={{
                marginBottom: '48px',
              }}
            >
              {t('BecomeOperatorView.freeTrial.actions.getStarted')}
            </Button>
            <List disablePadding>
              <OperatorCTAListItems />
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default HeroSection;
