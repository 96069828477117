import {
  ConfirmModal,
  FileTypeEnum,
  PageLoader,
  EditOrderCancelOrderSection,
  DEFAULT_MAX_PASSENGER_COUNT,
  PaymentMethod,
  EditOrderCardContainer,
  EditOrderMainTitle,
  OrderEditChangeTracker,
  OrderPublicDtoSchemaType,
  EditOrderCombinedSchemaType,
  editOrderCombinedSchema,
  OrderCancelled,
  GroupLeadLuggageOrderForm,
  GeneralOrderForm,
  getOrderEditFocusErrorList,
  setFieldError,
  orderEditErrorKeys,
  EditOrderSaveChangesSection,
} from 'remote/global';
import { OrderPaymentByProviderSchemaType } from '@/components/forms/CreateOrderForm/types';
import { ClientPaymentActiveTabEnum } from '@/utils/enums';
import OrderPaymentStepForm from '@/components/forms/CreateOrderForm/steps/OrderPaymentStepForm';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useCallback, useState, useMemo } from 'react';
import { debounce, differenceWith, isEqual } from 'lodash-es';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AxiosError } from 'axios';
import {
  usePostValidateOrder,
  useDeleteOrderMutation,
  useOrdersByBookingNumberQuery,
  useOrdersFilesQuery,
  usePutOrdersDetailsMutation,
  useDeleteOrdersFilesMutation,
  usePostOrdersFilesMutation,
  usePostOrdersPaymentStartMutation,
} from 'api/queries/orders';
import { useUIMapsGoogleLoaded } from 'api/queries/ui';
import { FileResponse } from 'utils/data-types';
import { Box } from '@mui/system';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { runSequentially } from 'utils/helpers';
import toast from 'react-hot-toast';
import { MobiferErrorResponse } from 'api/types';
import AppMenu from 'components/AppMenu';
import { useMapsGoogleConfigurationQuery } from 'api/queries/config';

const DEBOUNCE_TIME = 500;

function EditOrder({
  order,
  orderFiles,
  isOrderSuccess,
  isOrderLoading,
  orderError,
}: {
  order: OrderPublicDtoSchemaType;
  orderFiles: FileResponse[];
  isOrderSuccess: boolean;
  isOrderLoading: boolean;
  orderError: AxiosError<MobiferErrorResponse, any> | null;
}) {
  const tInstance = useTranslation();
  const { t, i18n } = tInstance;
  const { bookingNumber } = useParams();
  const [activeTab, setActiveTab] = useState<ClientPaymentActiveTabEnum>(
    ClientPaymentActiveTabEnum.BY_BANK
  );
  const postStartPaymentMutation = usePostOrdersPaymentStartMutation(bookingNumber!);
  const theme = useTheme();
  const navigate = useNavigate();
  const postValidateOrder = usePostValidateOrder(bookingNumber!);
  const smallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isPaymentStep, setIsPaymentStep] = useState(false);
  const deleteOrderMutation = useDeleteOrderMutation(bookingNumber!);
  const rides = order.rides ?? [];
  const maxPassengerCount = Math.max(...rides.map((r) => r.vehicle.seats));
  const origSignFiles = orderFiles?.filter((f) => f.fileType === FileTypeEnum.RIDE_SIGN);
  const origRideDetailsFiles = orderFiles?.filter((f) => f.fileType === FileTypeEnum.RIDE_DETAILS);
  const formInstance = useForm<EditOrderCombinedSchemaType>({
    defaultValues: {
      specialLuggageCount: null,
      specialLuggageType: null,
      pickUpLocationMessage: '',
      signFiles: origSignFiles,
      maxPassengerCount: DEFAULT_MAX_PASSENGER_COUNT,
      rideDetailsFiles: origRideDetailsFiles,
      ...(order as any),
      passengerCount: order.passengerCount.toString() ?? false,
      specialLuggage:
        typeof order.specialLuggageCount === 'number' && order.specialLuggageCount > 0,
      priceChanges: null,
      validationResult: null,
      isPaymentConfirmed: false,
    },
    resolver: zodResolver(editOrderCombinedSchema),
  });
  const {
    handleSubmit,
    reset,
    getValues,
    setError,
    watch,
    setValue,
    formState: { isSubmitting, isValidating, isLoading },
  } = formInstance;
  const isSaveChangesDisabled = isSubmitting || isValidating || isLoading;
  const [lastSubmittedValues, setLastSubmittedValues] = useState(getValues());
  const putOrdersDetailsMutation = usePutOrdersDetailsMutation(bookingNumber!);
  const deleteOrdersFiles = useDeleteOrdersFilesMutation(bookingNumber!);
  const postOrdersFiles = usePostOrdersFilesMutation(bookingNumber!);
  const validationResult = watch('validationResult');
  const { data: googleConfig } = useMapsGoogleConfigurationQuery();
  const focusErrorList = getOrderEditFocusErrorList(t, maxPassengerCount);

  const onValid = async (data: EditOrderCombinedSchemaType) => {
    const isPaymentConfirmed = getValues('isPaymentConfirmed');
    const amountToPayPresent = validationResult?.amountToPay && validationResult.amountToPay > 0;
    const { signFiles, rideDetailsFiles, ...rest } = data;
    const payload: EditOrderCombinedSchemaType = rest;

    try {
      const defaultFiles = [...(origSignFiles ?? []), ...(origRideDetailsFiles ?? [])];
      const files = [...(signFiles ?? []), ...(rideDetailsFiles ?? [])];
      // A file is uploaded if there is 'originalFile' attached
      const uploadedFiles = files.filter((f) => (f as any).originalFile != null);
      const removedFiles = differenceWith(defaultFiles, files, isEqual);
      const removedPromises = removedFiles.map(
        ({ fileName }) =>
          () =>
            deleteOrdersFiles.mutateAsync({ fileName })
      );
      const insertedPromises = uploadedFiles.map((file) => () => {
        const formData = new FormData();
        formData.append('file', (file as any).originalFile as File);
        formData.append('fileType', file.fileType);
        return postOrdersFiles.mutateAsync(formData);
      });

      // Call the promise functions sequentially
      await runSequentially(removedPromises);
      await runSequentially(insertedPromises);

      // used for PREPAID options
      if (amountToPayPresent && order.paymentMethod === PaymentMethod.PREPAID) {
        setIsPaymentStep(true);
        return;
      }

      // used to confirm payment for PAY_LATER in case of price change
      if (
        validationResult?.changeFeeInclVat !== 0 &&
        !isPaymentConfirmed &&
        order.paymentMethod === PaymentMethod.PAY_LATER
      ) {
        setIsConfirmModalOpen(true);
        return;
      }

      // used for PAY_LATER orders or when price dont increase
      await putOrdersDetailsMutation.mutateAsync(payload as any);
      setLastSubmittedValues(payload);
      toast.success(t('Toast.success'));
      setValue('validationResult', null);
      navigate(`/change-booking-details/${bookingNumber}/changed?type=${order.paymentMethod}`, {
        replace: true,
      });
    } catch (err: any) {
      const {
        errorCode,
        errorParams,
      }: { errorCode: string; errorParams: keyof typeof orderEditErrorKeys } = err.response.data;
      setValue('validationResult', null);
      setFieldError({ setError, focusErrorList, errorCode, errorParams });
      // TODO: Capture sentry error
    } finally {
      setValue('isPaymentConfirmed', false);
    }
  };

  const handlePayment = async (data: any) => {
    try {
      const castData = data as OrderPaymentByProviderSchemaType;
      const payload = {
        ...castData,
        offerUuid: validationResult?.uuid as string,
        lang: i18n.language,
        returnUrl: `${window?.location?.origin}/change-booking-redirect/${bookingNumber}/${validationResult?.uuid}`,
      };
      const response = await postStartPaymentMutation.mutateAsync(payload);

      if (response?.paymentUrl) {
        window.location.href = response.paymentUrl;
      }
    } catch (err) {
      // TODO: Sentry capture error
      toast.error(t('Toast.failure'));
    }
  };

  const debouncedSetValidationResult = useMemo(
    () =>
      debounce(async (validationPayload) => {
        try {
          const response = await postValidateOrder.mutateAsync(validationPayload);
          if (response?.newPriceInclVat) {
            setValue('validationResult', response);
            setValue('acceptedPriceInclVat', response?.newPriceInclVat);
            setValue('priceInclVat', response.newPriceInclVat);
          }
        } catch (error: any) {
          const {
            errorCode,
            errorParams,
          }: { errorCode: string; errorParams: keyof typeof orderEditErrorKeys } =
            error.response.data;
          setFieldError({ setError, focusErrorList, errorCode, errorParams });
        }
      }, DEBOUNCE_TIME),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue]
  );

  const setValidationResult = useCallback(
    (validationPayload: any) => {
      debouncedSetValidationResult(validationPayload);
    },
    [debouncedSetValidationResult]
  );

  const handleCancelBooking = async () => {
    if (!deleteOrderMutation.isPending) {
      try {
        await deleteOrderMutation.mutateAsync();
      } catch (err) {
        // TODO: Sentry capture error
        toast.error(t('Toast.failure'));
      }
    }
  };

  const handlePaymentConfirmation = () => {
    setValue('isPaymentConfirmed', true);
    setIsConfirmModalOpen(false);
    handleSubmit(onValid)();
  };

  const handleDiscardChanges = () => {
    reset(lastSubmittedValues);
  };

  const handleCancelModalClose = () => {
    setIsCancelModalOpen(false);
  };

  const handleConfirmModalClose = () => {
    setIsConfirmModalOpen(false);
  };

  if (isOrderLoading) {
    return (
      <>
        <AppMenu />
        <Box sx={{ padding: '0 1rem' }}>
          <EditOrderCardContainer>
            <PageLoader />
          </EditOrderCardContainer>
        </Box>
      </>
    );
  }

  const isOrderCancelled =
    orderError?.response?.data?.errorCode === 'CANNOT_RETRIEVE_CANCELLED_ORDER';
  if (isOrderCancelled) {
    return <OrderCancelled />;
  }

  if (!isOrderSuccess) return null;

  if (isPaymentStep) {
    return (
      <Box
        sx={{
          marginLeft: !smallScreen ? '-150px' : 0,
        }}
      >
        {smallScreen && <OrderEditChangeTracker formInstance={formInstance} order={order} />}
        <EditOrderCardContainer>
          <OrderPaymentStepForm
            onValid={handlePayment}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            onBack={() => setIsPaymentStep(false)}
          />
          {!smallScreen && <OrderEditChangeTracker formInstance={formInstance} order={order} />}
        </EditOrderCardContainer>
      </Box>
    );
  }

  return (
    <form id='orderEditContainer' onSubmit={handleSubmit(onValid)}>
      <Box
        sx={{
          marginLeft: !smallScreen ? '-250px' : 0,
        }}
      >
        <EditOrderCardContainer>
          <EditOrderMainTitle
            title={t('ChangeOrderDetailsView.title') as string}
            subtitle={t('ChangeOrderDetailsView.subtitle').toString()}
          />
          <GeneralOrderForm
            bookingNumber={bookingNumber!}
            order={order}
            googleConfig={googleConfig!}
            formInstance={formInstance}
            setValidationResult={setValidationResult}
            maxPassengerCount={maxPassengerCount}
          />
          {!smallScreen && (
            <OrderEditChangeTracker formInstance={formInstance} order={order}>
              <EditOrderSaveChangesSection
                handleDiscardChanges={handleDiscardChanges}
                isSaveChangesDisabled={isSaveChangesDisabled}
              />
            </OrderEditChangeTracker>
          )}
        </EditOrderCardContainer>
        <EditOrderCardContainer>
          <EditOrderMainTitle
            title={t('ChangeOrderDetailsView.steps.extraDetails.title') as string}
            subtitle={t('ChangeOrderDetailsView.steps.extraDetails.subtitle') as string}
          />
          <GroupLeadLuggageOrderForm formInstance={formInstance} />
        </EditOrderCardContainer>
        {smallScreen && (
          <OrderEditChangeTracker formInstance={formInstance} order={order}>
            <EditOrderSaveChangesSection
              handleDiscardChanges={handleDiscardChanges}
              isSaveChangesDisabled={isSaveChangesDisabled}
            />
          </OrderEditChangeTracker>
        )}
        <EditOrderCancelOrderSection onCancelOrderClick={() => setIsCancelModalOpen(true)} />
        <ConfirmModal
          open={isCancelModalOpen}
          setOpen={setIsCancelModalOpen}
          onClose={handleCancelModalClose}
          onConfirm={handleCancelBooking}
          title={t('OrderView.cancel.title').toString()}
          description={t('OrderView.cancel.subtitle').toString()}
        />
        <ConfirmModal
          open={isConfirmModalOpen}
          setOpen={setIsConfirmModalOpen}
          onClose={handleConfirmModalClose}
          onConfirm={handlePaymentConfirmation}
          title={t('OrderView.confirm.title').toString()}
          description={
            validationResult?.newPriceInclVat
              ? t('OrderView.confirm.subtitle', {
                  newPrice: validationResult?.newPriceInclVat,
                }).toString()
              : ''
          }
        />
      </Box>
    </form>
  );
}

function OrderEditView() {
  const { bookingNumber } = useParams();
  const {
    data: order,
    isSuccess: isOrderSuccess,
    isLoading: isOrderLoading,
    error: orderError,
  } = useOrdersByBookingNumberQuery(bookingNumber);
  const { isMapsLoaded } = useUIMapsGoogleLoaded();
  const { data: orderFiles } = useOrdersFilesQuery(bookingNumber);

  if (!isMapsLoaded || !order || !orderFiles) return <PageLoader />;

  return (
    <>
      <AppMenu />
      <Box
        sx={{
          padding: '0 1rem',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <EditOrder
          order={{ ...order }}
          orderFiles={orderFiles}
          isOrderSuccess={isOrderSuccess}
          isOrderLoading={isOrderLoading}
          orderError={orderError}
        />
      </Box>
    </>
  );
}

export default OrderEditView;
