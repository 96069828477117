import { useMutation, useQuery } from '@tanstack/react-query';
import { OfferSchemaType } from 'remote/global';
import { AxiosError } from 'axios';
import { AcceptOperatorOfferRequestSchemaType } from 'components/forms/MakeOfferForm/types';
import {
  fetchOperatorOffersByAccessToken,
  fetchOperatorOffersOperatorVehicles,
  postOperatorOffersApproval,
} from 'api/requests/requests';
import { STALE_FIVE_MINUTES } from 'api/types';

export const offersQueryKeys = {
  all: ['operator-offers'] as const,
  operatorOffers: (accessToken: string) => [...offersQueryKeys.all, accessToken] as const,
  operatorOrder: (accessToken: string) =>
    [...offersQueryKeys.all, 'operator-token', accessToken] as const,
  operatorOffersOperatorVehicles: (accessToken: string) =>
    [...offersQueryKeys.all, accessToken, 'operator', 'vehicles'] as const,
  operatorOrdersDriverCandidates: (accessToken: string) =>
    [...offersQueryKeys.all, 'operator-token', accessToken, 'driver-candidates'] as const,
  operatorOffersApproval: (accessToken: string) =>
    [...offersQueryKeys.all, accessToken, 'approval'] as const,
  postEditOrder: (accessToken: string) =>
    [...offersQueryKeys.all, 'operator-token', accessToken] as const,
};

export function useOperatorOffersQuery(accessToken?: string) {
  return useQuery({
    queryKey: offersQueryKeys.operatorOffers(accessToken!),
    queryFn: () => fetchOperatorOffersByAccessToken(accessToken!),
    staleTime: STALE_FIVE_MINUTES,
    enabled: accessToken != null,
    select: ({ data }) => data,
  });
}

export function useOperatorsOffersOperatorVehiclesQuery(accessToken?: string) {
  return useQuery({
    queryKey: offersQueryKeys.operatorOffersOperatorVehicles(accessToken!),
    queryFn: () => fetchOperatorOffersOperatorVehicles(accessToken!),
    staleTime: STALE_FIVE_MINUTES,
    enabled: accessToken != null,
    select: ({ data }) => data,
  });
}

export function useOperatorOffersApprovalMutation(accessToken?: string) {
  return useMutation<OfferSchemaType, AxiosError, AcceptOperatorOfferRequestSchemaType>({
    mutationKey: offersQueryKeys.operatorOffersApproval(accessToken!),
    mutationFn: (v) => postOperatorOffersApproval(accessToken!, v).then((d) => d.data),
  });
}
