import { useMutation } from '@tanstack/react-query';
import { useClientId } from 'api/queries/auth';
import { postClientsOffers, postClientsOrders } from 'api/requests/requests';
import { AxiosError } from 'axios';
import { OfferSchemaType, OrderPublicDtoSchemaType } from 'remote/global';
import { OfferPayload, OrderRequestPayload, StringOrNumber } from 'utils/data-types';

export const clientsQueryKeys = {
  all: ['clients'] as const,
  detail: (clientId: StringOrNumber) => [...clientsQueryKeys.all, clientId] as const,
  offers: (clientId: StringOrNumber) => [...clientsQueryKeys.detail(clientId), 'offers'] as const,
  orders: (clientId: StringOrNumber) => [...clientsQueryKeys.detail(clientId), 'orders'] as const,
};

export function usePostClientsOffersMutation() {
  const clientId = useClientId();

  return useMutation<OfferSchemaType, AxiosError, OfferPayload>({
    mutationKey: clientsQueryKeys.offers(clientId!),
    mutationFn: (v) => postClientsOffers(String(clientId), v).then((d) => d.data),
  });
}

export function usePostClientsOrdersMutation() {
  const clientId = useClientId();

  return useMutation<OrderPublicDtoSchemaType, AxiosError, OrderRequestPayload>({
    mutationKey: clientsQueryKeys.orders(clientId!),
    mutationFn: (v) => postClientsOrders(String(clientId), v).then((d) => d.data),
  });
}
