import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@mui/material';
import { Stack } from '@mui/system';
import {
  CheckboxInputField,
  ControlledField,
  GroupLeadSchemaType,
  PhoneInputField,
  OfferClientDataSchemaType,
  TextareaInputField,
} from 'remote/global';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { usePutOffersClientDataMutation } from 'api/queries/offers';
import {
  ClientContactInfoSchemaType,
  OrderGroupLeadSchemaType,
  orderGroupLeadSchema,
} from '../types';

const defaultGroupLeadValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
};

function OrderGroupLeadStepForm({
  onValid,
  onBack,
  defaultValues,
  contactInfo,
}: {
  onValid: SubmitHandler<GroupLeadSchemaType>;
  onBack: (data: GroupLeadSchemaType) => void;
  defaultValues?: GroupLeadSchemaType;
  contactInfo: ClientContactInfoSchemaType;
}) {
  const tInstance = useTranslation();
  const { t, i18n } = tInstance;
  const { refNumber } = useParams();
  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    trigger,
    formState: { isSubmitting, isValidating, isLoading },
  } = useForm<OrderGroupLeadSchemaType>({
    defaultValues: {
      clientIsGroupLead: false,
      pickUpLocationMessage: '',
      signText: '',
      groupLead: defaultGroupLeadValues,
      ...defaultValues,
    },
    resolver: zodResolver(orderGroupLeadSchema),
  });
  const putOffersClientDataMutation = usePutOffersClientDataMutation(refNumber!);
  const isClientGroupLead = watch('clientIsGroupLead');

  const handleOnValid = async (data: GroupLeadSchemaType) => {
    try {
      // TODO: Fix typing
      await putOffersClientDataMutation.mutateAsync({
        client: {
          ...contactInfo.client,
          lang: i18n.language,
        },
        ...data,
      } as unknown as OfferClientDataSchemaType);
      return onValid(data);
    } catch (err) {
      // TODO: Sentry error
      return Promise.reject(err);
    }
  };

  return (
    <form onSubmit={handleSubmit(handleOnValid)}>
      <Stack direction='column' spacing={1}>
        <ControlledField
          control={control}
          name='clientIsGroupLead'
          render={(renderProps) => (
            <CheckboxInputField
              {...renderProps}
              field={{
                ...renderProps.field,
                onChange: (e, checked) => {
                  if (checked) {
                    // Sets values for all groupLead keys where value is taken from contactInfo
                    setValue('groupLead', contactInfo.client.contactInfo);
                    trigger('groupLead');
                  } else {
                    // Resets all groupLead values
                    setValue('groupLead', defaultGroupLeadValues);
                  }
                  return renderProps.field.onChange(e, checked);
                },
              }}
            />
          )}
        />
        <ControlledField
          control={control}
          name='groupLead.firstName'
          translationKey='firstName'
          fieldProps={{ InputProps: { autoComplete: 'given-name' }, disabled: isClientGroupLead }}
        />
        <ControlledField
          control={control}
          name='groupLead.lastName'
          translationKey='lastName'
          fieldProps={{ InputProps: { autoComplete: 'family-name' }, disabled: isClientGroupLead }}
        />
        <ControlledField
          control={control}
          name='groupLead.email'
          translationKey='email'
          fieldProps={{
            InputProps: {
              type: 'email',
              autoComplete: 'email',
            },
            disabled: isClientGroupLead,
          }}
        />
        <ControlledField
          control={control}
          name='groupLead.phone'
          render={(renderProps) => (
            <PhoneInputField
              {...renderProps}
              translationKey='phone'
              fieldProps={{ disabled: isClientGroupLead }}
            />
          )}
        />
        <ControlledField
          control={control}
          name='pickUpLocationMessage'
          render={(renderProps) => (
            <TextareaInputField
              {...renderProps}
              fieldProps={{
                minRows: 2,
              }}
              translationKey='pickUpLocationMessageOptional'
            />
          )}
        />
        <ControlledField control={control} name='signText' translationKey='signTextOptional' />
      </Stack>
      <Stack direction='row' justifyContent='space-between' spacing={1} mt='2rem'>
        <Button
          type='button'
          onClick={() => onBack(getValues())}
          variant='outlined'
          disabled={isSubmitting || isValidating || isLoading}
        >
          {t('back')}
        </Button>
        <Button
          type='submit'
          disabled={isSubmitting || isValidating || isLoading}
          variant='contained'
        >
          {t('continue')}
        </Button>
      </Stack>
    </form>
  );
}

export default OrderGroupLeadStepForm;
