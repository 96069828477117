import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Grid } from '@mui/material';
import {
  ControlledField,
  DEFAULT_MAX_PASSENGER_COUNT,
  DateTimePickerInputField,
  RideOfferType,
  PassengerCountField,
  DEFAULT_MIN_ADVANCE_TIME,
  DEFAULT_MAX_ADVANCE_TIME,
  OneWayRideSchemaType,
  RideDetailsSchemaType,
  oneWayRideSchema,
  usePickupSpotsModal,
  StartAndEndLocationFieldWithSlots,
  getDateTimeShortFormatWithLocale,
  TravelTypeTabs,
} from 'remote/global';
import { useForm, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useMapsGoogleConfigurationQuery, useOffersSettingsQuery } from 'api/queries/config';
import { useBlockedAreasPickUpSpotsMutation } from 'api/queries/blocked-areas';

function VehicleOneWayRideStepForm({
  defaultValues,
  onValid,
  setFormResult,
  isCounterRunning,
  handleDateTimeAccept,
}: {
  defaultValues?: RideDetailsSchemaType;
  isCounterRunning?: boolean;
  onValid: (data: OneWayRideSchemaType) => void;
  setFormResult: React.Dispatch<React.SetStateAction<RideDetailsSchemaType | undefined>>;
  handleDateTimeAccept: (val: FieldValues | null) => void;
}) {
  const { data: googleConfig } = useMapsGoogleConfigurationQuery();
  const { data: offersSettings } = useOffersSettingsQuery();
  const tInstance = useTranslation();
  const { t, i18n } = tInstance;
  const { minAdvanceTime } = offersSettings ?? { minAdvanceTime: DEFAULT_MIN_ADVANCE_TIME };
  const maxAdvanceTime = DEFAULT_MAX_ADVANCE_TIME;

  const formInstance = useForm<OneWayRideSchemaType>({
    defaultValues: {
      startLocation: undefined,
      endLocation: undefined,
      startTime: '',
      passengerCount: '' as any,
      maxPassengerCount: DEFAULT_MAX_PASSENGER_COUNT,
      ...defaultValues,
      minStartTime: minAdvanceTime,
      maxStartTime: maxAdvanceTime,
      type: RideOfferType.ONE_WAY,
    },
    resolver: zodResolver(oneWayRideSchema),
  });
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { isSubmitting, isValidating, isLoading },
  } = formInstance;
  const pickupSpotsMutation = useBlockedAreasPickUpSpotsMutation();

  const { PickUpSpotsModalComponent, handleLocationChangeEvent } = usePickupSpotsModal({
    getPickupSpots: (coords) =>
      pickupSpotsMutation.mutateAsync({
        lat: String(coords.lat),
        lon: String(coords.lng),
      }),
    handlePickupSpotSelect: ({ fieldKey, spot }) => {
      setValue(fieldKey as keyof OneWayRideSchemaType, {
        address: spot.name,
        coords: spot.coords,
        placeId: spot.placeId,
      });
    },
  });

  return (
    <>
      <PickUpSpotsModalComponent />
      <form onSubmit={handleSubmit(onValid)}>
        <Grid item xs={12}>
          {/* TODO: Create new TravelTypeTabs component that adheres to control of useForm */}
          <TravelTypeTabs
            label={t('FormFields.rideType.label') as string}
            value={RideOfferType.ONE_WAY}
            options={[RideOfferType.ONE_WAY, RideOfferType.ROUND_TRIP, RideOfferType.MULTI_STOP]}
            handleChange={(value) => {
              setFormResult({
                ...getValues(),
                type: value as RideOfferType.ONE_WAY,
              }); // Sets the form defaultValues as the current forms values
            }}
          />
        </Grid>
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            md={5}
            sx={{
              display: 'flex',
              flexDirection: ['column', 'row'],
              width: '100%',
              gap: 1,
              '& > div': { width: '100%' },
            }}
          >
            <StartAndEndLocationFieldWithSlots
              form={formInstance}
              googleConfig={googleConfig!}
              onChangeEvent={handleLocationChangeEvent}
            />
          </Grid>
          <Grid container item xs={12} md={7} spacing={1}>
            <Grid item xs={12} md={6}>
              <ControlledField
                control={control}
                name='startTime'
                render={(renderProps) => (
                  <DateTimePickerInputField
                    {...renderProps}
                    fieldProps={{
                      onAccept: (val) => handleDateTimeAccept(val),
                      disablePast: true,
                      minDateTime: dayjs(minAdvanceTime),
                      maxDateTime: dayjs(maxAdvanceTime),
                      format: getDateTimeShortFormatWithLocale(i18n.language),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <PassengerCountField form={formInstance} />
            </Grid>
            <Grid item xs={12} md={2} mt='calc(2rem - 1px)'>
              <Button
                sx={{
                  display: 'flex',
                  ml: { xs: 'auto', md: 'unset' },
                  width: { md: '100%' },
                }}
                type='submit'
                variant='contained'
                color='primary'
                disableElevation
                disabled={isCounterRunning || isSubmitting || isValidating || isLoading}
              >
                {t('continue')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default VehicleOneWayRideStepForm;
