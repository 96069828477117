import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { colors } from 'remote/global';
import { Box, Grid, Typography, Link, ListItem } from '@mui/material';
import { LegalText } from 'utils/data-types';
import { useTranslation } from 'react-i18next';
import AppMenu from 'components/AppMenu';
import Footer from 'components/Footer';
import { parseParagraphs } from 'utils/helpers';

function LegalTextView() {
  const emailAddress = 'info@mobifer.com';
  const [legalText, setLegalText] = useState<LegalText | null>(null);
  const { pathname } = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    switch (pathname) {
      case '/privacy-policy':
        setLegalText({
          title: t('PrivacyPolicyView.title'),
          subtitle: t('PrivacyPolicyView.subtitle'),
          body: parseParagraphs(t('PrivacyPolicyView.terms')),
        });
        break;
      case '/terms-of-service':
        setLegalText({
          title: t('TermsOfServiceView.title'),
          subtitle: t('TermsOfServiceView.subtitle'),
          body: parseParagraphs(t('TermsOfServiceView.terms')),
        });
        break;
      case '/cookies':
        setLegalText({
          title: t('CookieBanner.title'),
          subtitle: '',
          body: parseParagraphs(t('CookieBanner.description')),
        });
        break;
      default:
    }
  }, [pathname, t]);

  if (!legalText) return null;

  return (
    <>
      <AppMenu />
      <Box display='flex' justifyContent='center' p='30px'>
        <Grid
          justifyContent='flex-start'
          container
          spacing={3}
          item
          sx={{
            marginTop: 0,
            maxWidth: '900px',
          }}
        >
          <Grid item xs={12}>
            <Typography
              gutterBottom
              sx={{
                color: colors.primary,
                fontWeight: 700,
                fontSize: {
                  xs: '28px',
                  md: '48px',
                },
              }}
            >
              {legalText.title}
            </Typography>
          </Grid>
          {legalText.subtitle && (
            <Grid item xs={12}>
              <Typography>{legalText.subtitle}</Typography>
            </Grid>
          )}
          {legalText.body.map(({ title, content }) => {
            return (
              <Grid item xs={12} key={title}>
                <Typography
                  gutterBottom
                  sx={{
                    fontSize: '20px',
                  }}
                >
                  {title}
                </Typography>
                {content.map((body) => {
                  const getTypographyElement = (element: React.ReactNode) => (
                    <Typography
                      gutterBottom
                      key={uuidv4()}
                      sx={{
                        whiteSpace: 'pre-line',
                        color: colors.gray2,
                        fontWeight: 500,
                        fontSize: '16px',
                      }}
                    >
                      {element}
                    </Typography>
                  );
                  if (body.includes('•')) {
                    return (
                      <ListItem
                        key={uuidv4()}
                        sx={{
                          whiteSpace: 'pre-line',
                          color: colors.gray2,
                          fontWeight: 500,
                          paddingTop: '4px',
                          paddingBottom: '4px',
                          fontSize: '16px',
                        }}
                      >
                        {body}
                      </ListItem>
                    );
                  }

                  if (body.includes(emailAddress)) {
                    const index = body.indexOf(emailAddress);
                    const firstPart = body.slice(0, index);
                    const secondPart = body.slice(index + emailAddress.length);
                    return getTypographyElement(
                      <>
                        {firstPart}
                        <Link
                          href={`mailto:${emailAddress}`}
                          sx={{
                            color: colors.primary,
                            fontWeight: 700,
                            fontSize: '16px',
                          }}
                        >
                          {emailAddress}
                        </Link>
                        {secondPart}
                      </>
                    );
                  }

                  return getTypographyElement(body);
                })}
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Footer />
    </>
  );
}

export default LegalTextView;
