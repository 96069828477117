import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { LanguageOptions, RideDetailsSchemaType } from 'remote/global';
import { useCallback } from 'react';

export const uiQueryKeys = {
  all: ['ui'] as const,
  isMapsLoaded: () => [...uiQueryKeys.all, 'isMapsLoaded'] as const,
  drawer: () => [...uiQueryKeys.all, 'drawer'] as const,
  adapterLocale: () => [...uiQueryKeys.all, 'adapterLocale'] as const,
  offerFormPreset: () => [...uiQueryKeys.all, 'offerFormPreset'] as const,
};

export function useUIMapsGoogleLoaded() {
  const queryClient = useQueryClient();
  const { data: isMapsLoaded } = useQuery({
    queryKey: uiQueryKeys.isMapsLoaded(),
    enabled: false,
    initialData: Boolean(window?.google?.maps),
  });

  const { mutate } = useMutation<boolean, AxiosError, boolean>({
    mutationKey: uiQueryKeys.isMapsLoaded(),
    mutationFn: (value) => Promise.resolve(value),
    onSuccess: (value) => {
      queryClient.setQueryData(uiQueryKeys.isMapsLoaded(), value);
    },
  });

  const setIsMapsLoaded = useCallback(
    (value: boolean) => {
      mutate(value);
    },
    [mutate]
  );

  return { isMapsLoaded, setIsMapsLoaded };
}

export function useUIDrawer() {
  const queryClient = useQueryClient();
  const { data: isMobileDrawerOpen } = useQuery({
    queryKey: uiQueryKeys.drawer(),
    enabled: false,
    initialData: false,
  });

  const { mutate } = useMutation<boolean, AxiosError, boolean>({
    mutationKey: uiQueryKeys.drawer(),
    mutationFn: (value) => Promise.resolve(value),
    onSuccess: (value) => {
      queryClient.setQueryData(uiQueryKeys.drawer(), value);
    },
  });

  const setIsMobileDrawerOpen = useCallback(
    (value: boolean) => {
      mutate(value);
    },
    [mutate]
  );

  return { isMobileDrawerOpen, setIsMobileDrawerOpen };
}

export function useUIAdapterLocale() {
  const queryClient = useQueryClient();
  const { data: adapterLocale } = useQuery({
    queryKey: uiQueryKeys.adapterLocale(),
    enabled: false,
    initialData: LanguageOptions.en,
  });

  const { mutate } = useMutation<string, AxiosError, string>({
    mutationKey: uiQueryKeys.adapterLocale(),
    mutationFn: (value) => Promise.resolve(value),
    onSuccess: (value) => {
      queryClient.setQueryData(uiQueryKeys.adapterLocale(), value);
    },
  });

  const setAdapterLocale = useCallback(
    (value: string) => {
      mutate(value);
    },
    [mutate]
  );

  return { adapterLocale, setAdapterLocale };
}

export function useUIOfferFormPreset() {
  const queryClient = useQueryClient();
  const { data: offerFormPreset } = useQuery<RideDetailsSchemaType>({
    queryKey: uiQueryKeys.offerFormPreset(),
    enabled: false,
    initialData: undefined,
  });

  const { mutate } = useMutation<RideDetailsSchemaType, AxiosError, RideDetailsSchemaType>({
    mutationKey: uiQueryKeys.offerFormPreset(),
    mutationFn: (value) => Promise.resolve(value),
    onSuccess: (value) => {
      queryClient.setQueryData(uiQueryKeys.offerFormPreset(), value);
    },
  });

  const setOfferFormPreset = useCallback(
    (value: RideDetailsSchemaType) => {
      mutate(value);
    },
    [mutate]
  );

  return { offerFormPreset, setOfferFormPreset };
}
