/* eslint-disable import/prefer-default-export */

import { Typography } from '@mui/material';
import { colors } from 'remote/global';

export function EndAdornment({ text }: { text: string }) {
  return (
    <Typography
      sx={{ color: colors.gray3, fontSize: '13px', userSelect: 'none', pointerEvents: 'none' }}
    >
      {text}
    </Typography>
  );
}
