import { Add, Remove } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import {
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useMediaQuery,
  Link,
} from '@mui/material';
import { colors } from 'remote/global';
import { Trans, useTranslation } from 'react-i18next';
import { makeTranslationArray } from 'utils/helpers';

function CustomExpandIcon() {
  return (
    <Box
      sx={{
        '.Mui-expanded & > .collapsIconWrapper': {
          display: 'none',
        },
        '.expandIconWrapper': {
          display: 'none',
        },
        '.Mui-expanded & > .expandIconWrapper': {
          display: 'block',
        },
        color: colors.primary,
        border: `2px solid ${colors.primary}`,
        borderRadius: '33px',
        lineHeight: 0,
      }}
    >
      <Box className='expandIconWrapper'>
        <Remove
          sx={{
            fontSize: '20px',
          }}
        />
      </Box>
      <Box className='collapsIconWrapper'>
        <Add
          sx={{
            fontSize: '20px',
          }}
        />
      </Box>
    </Box>
  );
}

type FAQItem = {
  title: string;
  description: string;
};
type FaqItemWithLinks = FAQItem & {
  title_links?: string[];
  description_links?: string[];
};

export function FAQList({ faqKey }: { faqKey: string }) {
  const tInstance = useTranslation();
  const { t, i18n } = tInstance;

  const items = makeTranslationArray<FaqItemWithLinks>(faqKey, ['title', 'description'], tInstance);

  if (items == null || !Array.isArray(items)) return null;

  const faqItems = items?.map(({ title, description, description_links }, index) => {
    const linksExist = description_links != null;
    return (
      <Accordion
        key={`${title}-${description}`}
        sx={{
          padding: '20px 0',
          '&:before': {
            backgroundColor: colors.gray6,
          },
          '& .MuiAccordionSummary-content.Mui-expanded': {
            marginBottom: '0px',
          },
        }}
      >
        <AccordionSummary
          aria-controls={`panel${index}a-content`}
          id={`panel${index}a-header`}
          expandIcon={<CustomExpandIcon />}
          sx={{
            padding: 0,
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '18px',
              lineHeight: '28px',
              color: colors.primary,
            }}
          >
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: 0,
          }}
        >
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '24px',
              color: colors.gray1,
              whiteSpace: 'pre-line',
            }}
          >
            {linksExist ? (
              <Trans
                t={t}
                i18n={i18n}
                i18nKey={`${faqKey}[${index}].description`}
                components={description_links.map((link) => (
                  <Link
                    key={link}
                    href={link}
                    target={link.includes('http') ? '_blank' : ''}
                    sx={{
                      color: `${colors.primary}`,
                      fontWeight: 600,
                      textDecoration: 'underline',
                      fontSize: '14px',
                      '&:hover': {
                        textDecoration: 'none',
                      },
                    }}
                  />
                ))}
              />
            ) : (
              description
            )}
          </Typography>
        </AccordionDetails>
      </Accordion>
    );
  });

  return (
    <Box
      sx={{
        '.Mui-expanded & > .collapsIconWrapper': {
          display: 'none',
        },
        '.expandIconWrapper': {
          display: 'none',
        },
        '.Mui-expanded & > .expandIconWrapper': {
          display: 'block',
        },
        marginTop: '64px',
        width: '100%',
        maxWidth: '768px',
      }}
    >
      {faqItems}
    </Box>
  );
}

type FAQSectionProps = {
  title: string;
  subtitle?: string;
  FAQListKey: string;
};

function FAQSection(props: FAQSectionProps) {
  const { title, subtitle, FAQListKey } = props;
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      sx={{
        padding: `96px ${smallScreen ? '16px' : 0}`,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box textAlign='center'>
        <Typography
          component='h3'
          sx={{
            color: smallScreen ? colors.tiffanyBlue : colors.primary,
            fontWeight: 600,
            fontSize: '36px',
            lineHeight: '44px',
            marginBottom: '20px',
          }}
        >
          {title}
        </Typography>
        {subtitle != null && (
          <Typography component='h4' sx={{ color: '#000', fontSize: ['16px', undefined, '20px'] }}>
            {subtitle}
          </Typography>
        )}
      </Box>
      <FAQList faqKey={FAQListKey} />
    </Box>
  );
}

export default FAQSection;
