import { Grid, Typography } from '@mui/material';
import { colors } from 'remote/global';
import { useTranslation } from 'react-i18next';

function AboutSection() {
  const { t } = useTranslation();

  return (
    <Grid
      container
      sx={{
        position: 'relative',
        padding: ['98px 16px', '98px 112px', null, '134px 112px'],
        display: 'flex',
        background: colors.primary,
        justifyContent: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
        borderTop: '1px solid #E4E7EC',
      }}
    >
      <Grid container item maxWidth='xl' spacing={3}>
        <Grid item lg={6} xs={12}>
          <Typography
            component='h3'
            sx={{
              fontSize: '36px',
              lineHeight: '3rem',
              whiteSpace: 'pre-line',
              fontWeight: 600,
              color: colors.secondary,
            }}
          >
            {t('LandingView.about.title')}
          </Typography>
        </Grid>
        <Grid item lg={6} xs={12}>
          <Typography
            sx={{ fontSize: '20px', fontWeight: 400, color: colors.secondary, maxWidth: 575 }}
          >
            {t('LandingView.about.description')}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AboutSection;
