import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Grid, Box, Paper, Link } from '@mui/material';
import {
  colors,
  OffersV2,
  OfferTripSchemaType,
  PageLoader,
  RideOfferType,
  RideDetailsSchemaType,
  OfferList,
  isB2bRole,
  LegSchemaType,
  TravelModeEnum,
  LocationBaseSchemaType,
  OperatorOfferSchemaType,
  CustomerOfferSchemaType,
  OfferHeaderTitle,
} from 'remote/global';
import CreateRideOffersForm from 'components/forms/CreateRideOffersForm/CreateRideOffersForm';
import dayjs from 'dayjs';
import { Trans, useTranslation } from 'react-i18next';
import { track } from 'utils/tracking';
import { getUrlByProject } from 'utils/helpers';
import AppMenu from 'components/AppMenu';
import Footer from 'components/Footer';
import { useAuthClientJwtQuery } from 'api/queries/auth';
import { useOffersByRefNumberQuery, usePostOffersMutation } from 'api/queries/offers';
import useTimer from 'utils/hooks/useTimer';
import OffersInquiry from 'components/OffersInquiry';
import { usePostClientsOffersMutation } from '@/api/queries/clients';
import { OfferPayload } from '@/utils/data-types';
import { keepPreviousData } from '@tanstack/react-query';
import { debounce } from 'lodash-es';

export const getIsCounterEnabled = (epoch?: number) => {
  if (epoch == null) return false;
  const toDate = dayjs().valueOf();
  return epoch >= toDate;
};

export const getCounterTimeRemainingInSeconds = (epoch?: number) => {
  if (epoch == null) return 0;
  return Math.max(0, Math.floor((epoch - dayjs().valueOf()) / 1000));
};

const getEndLocation = (type: RideOfferType, legs: LegSchemaType[]): LocationBaseSchemaType => {
  switch (type) {
    case RideOfferType.ONE_WAY: {
      return legs[legs.length - 1].endLocation!;
    }
    case RideOfferType.ROUND_TRIP: {
      const includesFerryTravel = legs.some((l) => l.travelMode === TravelModeEnum.FERRY);
      if (includesFerryTravel) {
        const firstFerryLegIndex = legs.findIndex((l) => l.travelMode === TravelModeEnum.FERRY);
        const nextLeg = legs[firstFerryLegIndex + 1];
        if (nextLeg) return nextLeg.endLocation!;
        return legs[firstFerryLegIndex].endLocation!;
      }
      return legs[legs.length - 1].startLocation!;
    }
    default: {
      return legs[legs.length - 1].endLocation!;
    }
  }
};

export type OffersPageState = {
  offersRequestedTimeEnd?: number;
  originalOperators: { label: string; value: number }[];
};

function getDefaultCustomerOffer(input: OffersV2): RideDetailsSchemaType {
  const trip = (input.trips as unknown as OfferTripSchemaType[])![0];
  const [ride] = trip.rides;
  const [leg] = ride.legs;

  const defaultData = {
    passengerCount: input.passengerCount!,
    startLocation: leg.startLocation!,
    startTime: leg.startTime || '',
    endLocation: getEndLocation(trip.type, ride.legs),
    type: trip.type as any,
  };

  switch (trip.type) {
    case RideOfferType.ROUND_TRIP: {
      const lastLeg = ride.legs[ride.legs.length - 1];
      return {
        ...defaultData,
        returnTime: lastLeg.startTime || '',
      };
    }
    case RideOfferType.MULTI_STOP: {
      return {
        type: trip.type,
        passengerCount: input.passengerCount!,
        legs: ride.legs,
      };
    }
    default: {
      return defaultData;
    }
  }
}

type WrappedOfferListProps = React.ComponentProps<typeof OfferList> & {
  handleSubmitFilters: (data: any) => void;
};
function WrappedOfferList(props: WrappedOfferListProps) {
  const [submitId, setSubmitId] = useState('');
  const { refNumber } = useParams();
  const { data: offers } = useOffersByRefNumberQuery(refNumber);

  // Track product impressions
  useEffect(() => {
    if (refNumber && submitId !== refNumber && offers != null) {
      setSubmitId(refNumber);
      track.product_impressions(offers as any); // TODO: Fix typing
    }
  }, [refNumber, submitId, offers]);

  return <OfferList {...props} />;
}

function OffersView() {
  const tInstance = useTranslation();
  const { t, i18n } = tInstance;
  const { data: userData } = useAuthClientJwtQuery();
  const role = userData?.['husky.roles'];
  const navigate = useNavigate();
  const { refNumber } = useParams();
  const {
    data: offers,
    isLoading: isLoadingOffers,
    isSuccess: isOffersSuccess,
    isRefetching: isRefetchingOffers,
  } = useOffersByRefNumberQuery(refNumber, {
    placeholderData: keepPreviousData,
  });

  const postOffersMutation = usePostOffersMutation();
  const postClientsOffersMutation = usePostClientsOffersMutation();

  const isReloadingOffers = isLoadingOffers || isRefetchingOffers;

  // Gets the RideDetailsSchemaType data when user lands on /offers/:refNumber with/out state
  const getPrefill = (): RideDetailsSchemaType | null => {
    if (offers == null || isLoadingOffers) return null;
    return getDefaultCustomerOffer(offers as any); // TODO: Fix typing
  };

  const prefill = useMemo(getPrefill, [offers, isLoadingOffers]);

  const { state } = useLocation();
  const { offersRequestedTimeEnd, originalOperators } = (state as OffersPageState) ?? {};
  const isCounterEnabled =
    isOffersSuccess &&
    offers.waitingForOperatorOffers &&
    getIsCounterEnabled(offersRequestedTimeEnd);
  const counterTimeRemainingInSeconds = getCounterTimeRemainingInSeconds(offersRequestedTimeEnd);

  const { counter, isRunning: isCounterRunning } = useTimer(counterTimeRemainingInSeconds, {
    isEnabled: isCounterEnabled,
  });

  const handleSubmitFilters = debounce(async (data: any) => {
    const payload: OfferPayload = {
      ...prefill,
      ...data,
    };

    const response = isB2bRole(role ?? '')
      ? await postClientsOffersMutation.mutateAsync(payload)
      : await postOffersMutation.mutateAsync(payload);

    const newOriginalOperators = response.trips
      .flatMap((trip) =>
        trip.operatorOffers.map((el) => ({ label: el.operatorName, value: el.operatorId }))
      )
      .filter((offer, index, self) => index === self.findIndex((o) => o.value === offer.value));

    const isOriginalOperatorsPresent = state?.originalOperators != null;
    navigate(`/offers/${response.refNumber}`, {
      replace: true,
      state: {
        // only set the originalOperators if it does not exist already
        originalOperators: isOriginalOperatorsPresent ? originalOperators : newOriginalOperators,
      },
    });
  }, 300);

  const isAnyOfferAvailable =
    offers != null && offers.trips.flatMap((trip) => trip.operatorOffers).length > 0;

  return (
    <>
      <AppMenu />
      <Box
        sx={{
          background: colors.primaryBlue5,
          py: { xs: 2, sm: 6 },
          px: 1,
          minHeight: '100vh',
        }}
      >
        <Grid item px={[1, 2]} py={[1, 2]}>
          <Paper
            elevation={3}
            sx={{
              display: 'flex',
              padding: ['1rem', undefined, '2rem'],
              justifyContent: 'center',
              backgroundColor: '#FFF',
              width: '100%',
              border: `1px solid ${colors.tiffanyBlue3}`,
              maxWidth: '1200px',
              mx: 'auto',
            }}
          >
            {isLoadingOffers && <PageLoader />}
            {isLoadingOffers === false && offers == null && (
              <OfferHeaderTitle
                title={t('OffersView.expired.title')}
                subtitle={
                  <Trans
                    t={t}
                    i18n={i18n}
                    i18nKey='OffersView.expired.subtitle'
                    components={[
                      <Link
                        key='0'
                        href='mailto:hi@mobifer.com'
                        sx={{
                          color: `${colors.primary}`,
                          fontWeight: 600,
                          textDecoration: 'underline',
                          fontSize: '14px',
                          '&:hover': { textDecoration: 'none' },
                        }}
                      />,
                    ]}
                  />
                }
              />
            )}
            {/* This prefill boolean check makes sure that the CreateRideOffersForm gets initialized with the correct offer.type */}
            {offers != null && prefill != null && (
              <CreateRideOffersForm defaultValues={prefill} isCounterRunning={isCounterRunning} />
            )}
          </Paper>
        </Grid>
        <OffersInquiry
          isCounterEnabled={isCounterEnabled}
          isCounterRunning={isCounterRunning}
          counter={counter}
        />
        {/* Check makes sure that defaultValues gets initialized correctly */}
        {offers != null && !isLoadingOffers && isAnyOfferAvailable && (
          <WrappedOfferList
            defaultValues={offers as unknown as CustomerOfferSchemaType}
            originalOperators={originalOperators}
            isLoadingOffers={isReloadingOffers}
            handleSubmitFilters={handleSubmitFilters}
            handleSelectOffer={(offer: OperatorOfferSchemaType) => {
              track.add_to_cart(offer);

              if (isB2bRole(role ?? '')) {
                window.location.href = `${getUrlByProject('corporate')}/main/finishReservation/${
                  offers.refNumber
                }/${offer.uuid}`;
              } else {
                navigate(`/offers/continue-booking/${offers.refNumber}/${offer.uuid}`);
              }
            }}
          />
        )}
      </Box>
      <Footer />
    </>
  );
}

export default OffersView;
