import { Grid } from '@mui/material';
import { Box } from '@mui/system';
import { LogoElement } from 'remote/global';

function CardContainer({ children }: { children: React.ReactNode }) {
  return (
    <Grid container sx={{ mt: { md: 6 } }}>
      <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
        <Box
          sx={{
            padding: { xs: '2.5rem 1rem', md: '2.5rem' },
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: '1009px',
            overflow: 'hidden',
            position: 'relative',
            boxShadow: { md: '0px 4px 15px rgba(0, 0, 0, 0.08)' },
          }}
        >
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <LogoElement
              color='blue'
              sx={{
                bottom: '40px',
                left: '-90px',
                position: 'absolute',
                pointerEvents: 'none',
              }}
            />
            <LogoElement
              color='red'
              sx={{
                top: '40px',
                right: '-90px',
                position: 'absolute',
                pointerEvents: 'none',
              }}
            />
          </Box>

          {children}
        </Box>
      </Grid>
    </Grid>
  );
}

export default CardContainer;
