import { createI18nInstance } from 'remote/global';
import i18n from 'i18next';

const defaultNS = 'translation';
const projectPaths = [
  {
    projectToken: '4ad3601890614f259b0a8feb102a1033', // Mobifer Common Translations
    ns: ['common'],
  },
  {
    projectToken: 'f55d4fbb44364695a6968c75c6443a41', // Mobifer Landing FE
    ns: [defaultNS],
  },
];

const localI18nInstance = createI18nInstance(projectPaths, 'landing');

export { localI18nInstance };
export default i18n;
