import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LogoElement } from 'remote/global';
import { Box, Stack, Button } from '@mui/material';
import { getUrlByProject } from 'utils/helpers';

interface Props {
  children?: React.ReactNode;
  token: string;
}

function SuccessComponent({ children, token }: Props) {
  const { t } = useTranslation();
  return (
    <Box flex='1' position='relative' overflow='hidden'>
      <LogoElement
        sx={{
          maxWidth: '100%',
          top: '0',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: -1,
          position: 'absolute',
          pointerEvents: 'none',
        }}
        color='red'
        size='big'
      />
      <Stack textAlign='center' my={6} gap={2} alignItems='center' p='0 16px'>
        {children}
        <Button
          component={RouterLink}
          target='_blank'
          to={`${getUrlByProject('operator')}/sign-up/${token}`}
        >
          {t('MakeOfferView.completed.actions.joinMobifer')}
        </Button>
      </Stack>
    </Box>
  );
}

export default SuccessComponent;
