import { useMutation } from '@tanstack/react-query';
import { fetchBlockedAreasPickUpSpots } from 'api/requests/requests';
import { CoordsParams } from 'api/types';
import { AxiosError } from 'axios';
import { PickupSpot } from 'remote/global';

export const blockedAreasQueryKeys = {
  all: ['blocked-areas'] as const,
  pickUpSpots: (params: CoordsParams) =>
    [...blockedAreasQueryKeys.all, 'pick-up-spots', params] as const,
};

export function useBlockedAreasPickUpSpotsMutation(params?: CoordsParams) {
  return useMutation<PickupSpot[], AxiosError, CoordsParams>({
    mutationKey: blockedAreasQueryKeys.pickUpSpots(params!),
    mutationFn: (v) => fetchBlockedAreasPickUpSpots(v).then((d) => d.data),
  });
}
