import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Stack } from '@mui/system';
import { Button, Grid, Paper, Typography } from '@mui/material';
import { ContactSupportTrans, colors, OrderDtoSchemaType } from 'remote/global';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import SuccessComponent from 'components/SuccessComponent';
import { useTranslation } from 'react-i18next';
import { IdValueDtoLongString } from 'utils/data-types';
import { usePostOrdersOperatorTokenMutation } from 'api/queries/orders';
import OrderDriverFormFields from './OrderDriverFormFields';
import { EditOrderRequestSchemaType, editOrderRequestSchema } from './types';
import { OrderInfo } from '../MakeOfferForm/NewOfferInfo';

function OrderDriverForm({
  defaultValues,
  order,
  drivers,
}: {
  defaultValues?: Partial<EditOrderRequestSchemaType>;
  order: OrderDtoSchemaType;
  drivers: IdValueDtoLongString[];
}) {
  const formInstance = useForm<EditOrderRequestSchemaType>({
    defaultValues: {
      newDriver: null,
      driverId: null,
      ...defaultValues,
    },
    resolver: zodResolver(editOrderRequestSchema),
  });
  const {
    handleSubmit,
    formState: { isSubmitting, isLoading },
  } = formInstance;

  const tInstance = useTranslation();
  const { token } = useParams();
  const { t } = tInstance;
  const startEditOrderMutation = usePostOrdersOperatorTokenMutation(token!);
  const { isSuccess } = startEditOrderMutation;

  const onValid = (data: EditOrderRequestSchemaType) => startEditOrderMutation.mutate(data);

  if (isSuccess) {
    return (
      <SuccessComponent token={token!}>
        <Stack gap={4}>
          <Typography variant='h2' sx={{ fontWeight: 700, color: colors.primary }}>
            {t('MakeOfferDriverView.completed.title')}
          </Typography>
          <Typography variant='subtitle2' fontSize='medium'>
            {t('MakeOfferDriverView.completed.subtitle')}
          </Typography>
          <Typography variant='subtitle2' fontSize='medium'>
            {t('MakeOfferDriverView.completed.reminder.title')}
          </Typography>
          <Typography variant='subtitle2' fontSize='medium'>
            {t('MakeOfferDriverView.completed.reminder.subtitle')}
          </Typography>
        </Stack>
      </SuccessComponent>
    );
  }

  return (
    <form onSubmit={handleSubmit(onValid)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          alignItems: 'center',
          padding: '0 1rem 3rem',
        }}
      >
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Stack textAlign='center' my={6}>
            <Typography variant='h2' sx={{ mb: '16px', fontWeight: 700, color: colors.primary }}>
              {t('MakeOfferDriverView.title')}
            </Typography>
            <ContactSupportTrans i18nKey='MakeOfferView.subtitle' />
          </Stack>
        </Box>
        <Grid container spacing={4} maxWidth='1200px'>
          <Grid item display='flex' flexDirection='column' xs={12} md={6}>
            <Typography variant='numberText' mb='20px' color='primary' fontWeight='600'>
              {t('MakeOfferDriverView.driverDetails.title')}
            </Typography>
            <Paper
              elevation={3}
              sx={{
                flexDirection: 'column',
                display: 'flex',
                p: '30px 25px',
                mb: '12px',
                borderRadius: '16px',
              }}
            >
              <OrderDriverFormFields drivers={drivers} formInstance={formInstance} />
            </Paper>
          </Grid>
          <Grid
            xs={12}
            md={6}
            item
            display='flex'
            flexDirection='column'
            sx={{
              width: '420px',
            }}
          >
            <Typography variant='numberText' mb='20px' color='primary' fontWeight='600'>
              {t('MakeOfferDriverView.orderDetails.title')}
            </Typography>
            <OrderInfo order={order} />
            <Button
              type='submit'
              variant='contained'
              disabled={isSubmitting || isLoading}
              fullWidth
              sx={{ mt: '2rem' }}
            >
              {t('confirm')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
}

export default OrderDriverForm;
