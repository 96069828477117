import { ErrorFallback } from 'remote/global';
import { RouterProvider } from 'react-router-dom';
import useAppRouter from 'utils/hooks/useAppRouter';
import { useUsersCurrentAccessesQuery } from 'api/queries/users';

function Content() {
  // At this point we must already have information regarding state.auth to correctly assign routes to the customer
  const router = useAppRouter();
  return (
    <div className='App' style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <RouterProvider router={router} fallbackElement={<ErrorFallback />} />
    </div>
  );
}

function App() {
  const { isLoading } = useUsersCurrentAccessesQuery();

  // Prevents flickering of login screen on page refresh
  if (isLoading) return null;

  return <Content />;
}

export default App;
