import { useTheme } from '@mui/material/styles';
import { Box, Typography, List, useMediaQuery, Button } from '@mui/material';
import { colors } from 'remote/global';
import { Link as RouterLink } from 'react-router-dom';
import OperatorCTAListItems from 'components/landing/OperatorCTAListItems';
import { useTranslation } from 'react-i18next';
import CTAImage from 'assets/operator-cta.png';

function CTASection() {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down(1350));
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        padding: `98px  ${sm ? '16px' : '112px'}`,
        flexDirection: smallScreen ? 'column-reverse' : 'row',
      }}
    >
      <Box>
        <Box
          sx={{
            backgroundImage: `url(${CTAImage})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            width: '576px',
            maxWidth: '100%',
            height: '592px',
          }}
        />
      </Box>
      <Box ml={smallScreen ? 0 : '64px'}>
        <Typography
          component='h3'
          sx={{
            color: colors.primary,
            fontWeight: 600,
            fontSize: '36px',
            lineHeight: '44px',
          }}
        >
          {t('LandingView.operator.title')}
        </Typography>
        <Typography
          sx={{
            color: 'black',
            fontWeight: 400,
            fontSize: '20px',
            lineHeight: '30px',
            margin: '40px 0 37px',
            maxWidth: 600,
          }}
        >
          {t('LandingView.operator.description')}
        </Typography>
        <Button
          sx={{
            marginBottom: '40px',
            paddingLeft: { xs: '0', sm: '94px' },
            paddingRight: { xs: '0', sm: '94px' },
            width: { xs: '100%', sm: 'auto' },
          }}
          component={RouterLink}
          to='/become-operator'
        >
          {t('LandingView.operator.actions.becomeOperator')}
        </Button>
        <List sx={{ display: smallScreen ? ' none' : 'block' }} disablePadding>
          <OperatorCTAListItems />
        </List>
      </Box>
    </Box>
  );
}

export default CTASection;
