import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import { COOKIE_CONSENT_KEY, COOKIE_CONSENT_TRUTHY } from 'utils/enums';
import { grantFBQConsent, grantGTMConsent } from 'utils/cookie-helpers';
import { isFBQInitialized, isGTMInitialized } from 'utils/tracking';
import { isAnalyticsEnabled } from 'utils/helpers';
import Setup from './Setup';

function RootSetup() {
  const { t, i18n } = useTranslation();
  const [isGTMConsentSet, setIsGTMConsentSet] = useState(false);
  const [isFBQConsentSet, setIsFBQConsentSet] = useState(false);
  const cookieConsent = Cookies.get(COOKIE_CONSENT_KEY);

  useEffect(() => {
    document.title = t('Meta.title');
    document.documentElement.lang = i18n.language;
    document
      .querySelector('meta[name="description"]')
      ?.setAttribute('content', t('Meta.description'));
  }, [t, i18n]);

  // Initializes the GTM cookie consent if it is already set
  useEffect(() => {
    if (isAnalyticsEnabled && isGTMInitialized) {
      if (cookieConsent === COOKIE_CONSENT_TRUTHY && !isGTMConsentSet) {
        grantGTMConsent();
        setIsGTMConsentSet(true);
      }
    }
  }, [cookieConsent, isGTMConsentSet]);

  // Initializes the FBQ cookie consent if it is already set
  useEffect(() => {
    if (isAnalyticsEnabled && isFBQInitialized) {
      if (cookieConsent === COOKIE_CONSENT_TRUTHY && !isFBQConsentSet) {
        grantFBQConsent();
        setIsFBQConsentSet(true);
      }
    }
  }, [cookieConsent, isFBQConsentSet]);

  return <Setup />;
}

export default RootSetup;
