import { useEffect } from 'react';
import { Navigate, useParams, useSearchParams, useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { PageLoaderFullScreen, DecodedOrderToken, OrderPaymentStatusEnum } from 'remote/global';
import { useOrdersByBookingNumberQuery } from '@/api/queries/orders';
import { useOffersPaymentStatusQuery } from '@/api/queries/offers';
import { ROUTES } from 'utils/routes';

function OrderCompleteRedirectView() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { refNumber, uuid } = useParams();
  const orderToken = searchParams.get('order-token');

  const isFetchPaymentStatusEnabled = orderToken != null;
  const {
    data: paymentStatus,
    isFetched: isPaymentStatusFetched,
    isFetching: isFetchingPaymentStatus,
  } = useOffersPaymentStatusQuery(isFetchPaymentStatusEnabled ? refNumber : undefined);

  // Checks if payment status is available and that BE has recieved information about that payment
  const isPaymentStatusQueryPolling =
    isFetchPaymentStatusEnabled &&
    ![paymentStatus?.orderCreated, paymentStatus?.expired, paymentStatus?.failed].some(Boolean);

  const isFetchOrderEnabled =
    !isFetchPaymentStatusEnabled || (isPaymentStatusFetched && !isPaymentStatusQueryPolling);

  const orderQuery = useOrdersByBookingNumberQuery(
    isFetchOrderEnabled ? paymentStatus?.bookingNumber! : undefined
  );
  const {
    isError: isOrderError,
    isFetching: isFetchingOrder,
    isSuccess: isSuccessOrder,
  } = orderQuery;

  const isFetching = isFetchingPaymentStatus || isPaymentStatusQueryPolling || isFetchingOrder;

  // Redirect the customer to retry the payment if it is not PAID
  useEffect(() => {
    if (orderToken != null && orderToken.length > 0) {
      const decoded: DecodedOrderToken = jwtDecode(orderToken);
      if (decoded.paymentStatus !== OrderPaymentStatusEnum.PAID) {
        // Redirect the user to complete the payment again
        navigate(`/offers/continue-booking/${refNumber}/${uuid}?retryPayment`);
        return;
      }

      if (isSuccessOrder && paymentStatus?.bookingNumber) {
        navigate(`/order-complete/${paymentStatus.bookingNumber}/${uuid}`);
      }
    }
  }, [isSuccessOrder, navigate, orderToken, paymentStatus?.bookingNumber, refNumber, uuid]);

  if (isFetching) {
    return <PageLoaderFullScreen />;
  }

  // Redirect user when no order found (expired or otherwise)
  if (isOrderError) {
    return <Navigate to={ROUTES.BaseRoutes.ROOT} replace />;
  }

  return null;
}

export default OrderCompleteRedirectView;
