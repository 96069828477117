import { useState } from 'react';
import { colors, ControlledField, isAdminRole, isB2bRole, isOperatorRole } from 'remote/global';
import { UserData } from 'utils/data-types';
import { Typography, Link, InputAdornment, IconButton, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import jwtDecode from 'jwt-decode';
import { setCookies } from 'utils/cookie-helpers';
import { getUrlByProject } from 'utils/helpers';
import { captureException } from '@sentry/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { Stack } from '@mui/system';
import { RemoveRedEyeOutlined } from '@mui/icons-material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useAuthLoginMutation } from 'api/queries/auth';
import { ROUTES } from 'utils/routes';
import { loginSchema, LoginSchemaType } from './types';

function LoginForm() {
  const [showPasswordField, setShowPasswordField] = useState(false);
  const tInstance = useTranslation();
  const { t, i18n } = tInstance;
  const navigate = useNavigate();
  const loginMutation = useAuthLoginMutation();
  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isValidating, isLoading },
  } = useForm<LoginSchemaType>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: zodResolver(loginSchema),
  });

  const onValid = async (data: LoginSchemaType) => {
    try {
      const response = await loginMutation.mutateAsync(data);

      if (response?.jwt) {
        const decodedJwt: UserData = await jwtDecode(response.jwt);
        const role = decodedJwt['husky.roles'];

        // Sets the cookies for a given project (based on role)
        await setCookies(response);

        // Here we need to check if the logged in user is actually with a role that is valid for this project
        // We only redirect the user when they log in with a different role that is assigned to this project
        // Otherwise we still allow the ability to log in to the current project

        // For this project, we only redirect if the user is an operator or admin

        if (isOperatorRole(role))
          window.location.href = `${getUrlByProject('operator')}/redirect?refresh=${
            response.refreshToken
          }`;
        else if (isAdminRole(role))
          window.location.href = `${getUrlByProject('admin')}/redirect?refresh=${
            response.refreshToken
          }`;
        else if (isB2bRole(role))
          window.location.href = `${getUrlByProject('corporate')}/redirect?refresh=${
            response.refreshToken
          }`;
        else {
          // User will be logged in automatically
          navigate('/', { replace: true });
        }
      } else {
        toast.error(t('Toast.incorrectCredentials'));
      }
    } catch (err: any) {
      toast.error(t('Toast.incorrectCredentials'));
      captureException(err);
    }
  };

  return (
    <form onSubmit={handleSubmit(onValid)} style={{ width: '100%', maxWidth: '350px' }}>
      <Typography
        sx={{
          color: colors.primary,
          fontWeight: 600,
        }}
        fontSize='30px'
      >
        {t('LoginView.title')}
      </Typography>
      <Typography mt='12px' mb='32px' variant='cardText'>
        {t('LoginView.subtitle')}
      </Typography>
      <Stack direction='column' spacing={1} maxWidth='400px' textAlign='left'>
        <ControlledField
          control={control}
          name='email'
          focusInitial
          fieldProps={{
            InputProps: {
              type: 'email',
              autoComplete: 'email',
            },
          }}
        />
        <ControlledField
          control={control}
          name='password'
          fieldProps={{
            helperText: '',
            InputProps: {
              type: showPasswordField ? 'text' : 'password',
              autoComplete: 'password',
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    tabIndex={-1}
                    onMouseDown={() => setShowPasswordField(true)}
                    onMouseUp={() => setShowPasswordField(false)}
                    onMouseLeave={() => setShowPasswordField(false)}
                    onTouchStart={() => setShowPasswordField(true)}
                    onTouchEnd={() => setShowPasswordField(false)}
                  >
                    <RemoveRedEyeOutlined />
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        />
        <Link component={RouterLink} to={ROUTES.BaseRoutes.PASSWORD_RESET}>
          {t('LoginView.forgotPassword.title')}
        </Link>
      </Stack>
      <Button
        sx={{ marginTop: '24px', marginBottom: '16px' }}
        fullWidth
        type='submit'
        variant='contained'
        color='primary'
        disableElevation
        disabled={isSubmitting || isValidating || isLoading}
      >
        {t('actions.user.login')}
      </Button>
      <Typography variant='body2'>{t('LoginView.signup.title')}</Typography>
      <Typography variant='body2' display='flex' flexDirection='column'>
        <Link
          href={`${getUrlByProject('operator')}/sign-up?lang=${i18n.language}`}
          sx={{ fontSize: '14px', color: colors.primary, fontWeight: '500' }}
        >
          {t('LoginView.signup.options.operator')}
        </Link>
      </Typography>
      <Typography variant='body2' display='flex' flexDirection='column'>
        <Link
          href={`${getUrlByProject('corporate')}/sign-up?lang=${i18n.language}`}
          sx={{ fontSize: '14px', color: colors.primary, fontWeight: '500' }}
        >
          {t('LoginView.signup.options.corporate')}
        </Link>
      </Typography>
    </form>
  );
}

export default LoginForm;
