import { contactInfoDtoSchema, zo } from 'remote/global';

const feedbackRequestSchema = zo.object({
  email: zo.string().min(1).email(),
  lang: zo.string().max(3),
  message: zo.string().min(1).max(256),
  name: zo.string().min(1).max(64),
  phoneNumber: contactInfoDtoSchema.shape.phone,
});

type FeedbackRequestSchemaType = zo.infer<typeof feedbackRequestSchema>;

export { feedbackRequestSchema };
export type { FeedbackRequestSchemaType };
