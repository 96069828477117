import { useState } from 'react';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { OrderFormStep, OrderStepKey } from 'components/CreateOrder';
import {
  ClientType,
  GroupLeadSchemaType,
  Offer,
  StartPaymentRequestSchemaType,
  colors,
  isB2bRole,
  removeEmptyStringKeys,
} from 'remote/global';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ClientPaymentActiveTabEnum } from 'utils/enums';
import { track } from 'utils/tracking';
import { toast } from 'react-hot-toast';
import { usePostOffersPaymentStartMutation } from 'api/queries/offers';
import { usePostClientsOrdersMutation } from 'api/queries/clients';
import { useAuthClientJwtQuery } from 'api/queries/auth';
import { usePostOrdersMutation } from 'api/queries/orders';
import { OrderRequestPayload } from 'utils/data-types';
import OrderAccommodationStepForm from './steps/OrderAccommodationStepForm';
import OrderContactDetailsStepForm from './steps/OrderContactDetailsStepForm';
import OrderGroupLeadStepForm from './steps/OrderGroupLeadStepForm';
import OrderPaymentStepForm from './steps/OrderPaymentStepForm';
import {
  ClientContactInfoSchemaType,
  OrderAccommodationSchemaType,
  OrderPaymentByProviderSchemaType,
} from './types';

type FormResult = [
  ClientContactInfoSchemaType?,
  GroupLeadSchemaType?,
  OrderAccommodationSchemaType?,
  OrderPaymentByProviderSchemaType?
];
export type OrderFormResult = FormResult;

type CreateOfferOrderFormProps = {
  defaultValues?: OrderFormResult;
  steps: OrderFormStep[];
  activeStep: OrderFormStep;
  setActiveStep: (step: OrderFormStep) => void;
  offer: Offer;
};

function CreateOrderForm({
  defaultValues = [],
  steps,
  activeStep,
  setActiveStep,
  offer,
}: CreateOfferOrderFormProps) {
  const { t, i18n } = useTranslation();
  const [activeTab, setActiveTab] = useState<ClientPaymentActiveTabEnum>(
    ClientPaymentActiveTabEnum.BY_BANK
  );
  const [formResult, setFormResult] = useState<FormResult>(defaultValues);
  const [clientValues] = defaultValues;
  const [activeClientTypeTab, setActiveClientTypeTab] = useState(
    clientValues?.client?.type ?? ClientType.PRIVATE
  );
  const [contactInfoValues, groupLeadValues, accommodationValues, paymentValues] = formResult;
  const { refNumber, uuid } = useParams();
  const navigate = useNavigate();
  const postStartPaymentMutation = usePostOffersPaymentStartMutation(refNumber!);
  const postOrdersMutation = usePostOrdersMutation();
  const postClientsOrdersMutation = usePostClientsOrdersMutation();
  const { data: userData } = useAuthClientJwtQuery();
  const role = userData?.['husky.roles'];

  const contents = steps.find((s) => s.stepKey === activeStep.stepKey);

  const setFormResultForStep = (step: number, data: any) => {
    setFormResult((prevState) => {
      if (prevState == null || !Array.isArray(prevState) || prevState.length <= 0)
        return [data] as unknown as FormResult;
      const newState = prevState;
      newState[step] = data;
      return newState;
    });
  };

  const getPrevStep = (currentStep: OrderFormStep) => {
    // We use array indexes instead of object stepIndex because the 'steps' array is filtered
    const currentIndex = steps.findIndex((el) => el.stepIndex === currentStep.stepIndex);
    const stepElement = steps[currentIndex - 1];
    return stepElement;
  };

  const getNextStep = (currentStep: OrderFormStep) => {
    // We use array indexes instead of object stepIndex because the 'steps' array is filtered
    const currentIndex = steps.findIndex((el) => el.stepIndex === currentStep.stepIndex);
    const stepElement = steps[currentIndex + 1];
    return stepElement;
  };

  const handleGoBack = (data: any) => {
    setFormResultForStep(activeStep.stepIndex, data);
    const prevStep = getPrevStep(activeStep);
    if (prevStep) setActiveStep(prevStep);
  };

  const handleValidStep = async (data: any) => {
    const nextStep = getNextStep(activeStep);

    // If is not the last step
    if (nextStep) {
      setFormResultForStep(activeStep.stepIndex, data);
      setActiveStep(nextStep);
      return;
    }

    try {
      switch (activeTab) {
        case ClientPaymentActiveTabEnum.BY_BANK:
        case ClientPaymentActiveTabEnum.BY_CARD: {
          try {
            const castData = data as OrderPaymentByProviderSchemaType;
            const payload: StartPaymentRequestSchemaType = {
              ...castData,
              offerUuid: uuid as string,
              lang: i18n.language,
              // This UUID will be caught and used for redirecting the customer back to the payment step (we have to know the offer uuid)
              returnUrl: `${window?.location?.origin}/order-complete-redirect/${refNumber}/${uuid}`,
            };
            const response = await postStartPaymentMutation.mutateAsync(payload);

            if (response != null) {
              track.add_payment_info(offer);
              if (response?.paymentUrl) window.location.href = response.paymentUrl;
            }
          } catch (err) {
            // TODO: Sentry capture error
          }
          break;
        }
        case ClientPaymentActiveTabEnum.BY_INVOICE: {
          const payload: OrderRequestPayload = removeEmptyStringKeys({
            operatorOfferUuid: uuid!,
          });

          const response = isB2bRole(role ?? '')
            ? await postClientsOrdersMutation.mutateAsync(payload)
            : await postOrdersMutation.mutateAsync(payload);
          if (response != null) navigate(`/order-complete/${response.bookingNumber}/${uuid}`);
          break;
        }
        default:
          throw new Error(`Unhandled payment type for ${activeStep}`);
      }
    } catch (err) {
      // TODO: Capture sentry error
      toast.error(t('Toast.failure'));
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        maxWidth: '1500px',
      }}
    >
      <Box
        sx={{ display: 'flex', flexDirection: 'column', gap: 3, maxWidth: '400px', width: '100%' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Typography
            sx={{
              color: colors.primary,
              fontWeight: 700,
              textAlign: 'center',
              fontSize: '30px',
            }}
          >
            {contents?.label}
          </Typography>
          <Typography
            sx={{
              color: colors.gray1,
              fontWeight: 400,
              textAlign: 'center',
              fontSize: '16px',
            }}
          >
            {contents?.description}
          </Typography>
        </Box>
        {activeStep.stepKey === OrderStepKey.CLIENT_DATA && (
          <OrderContactDetailsStepForm
            offer={offer}
            activeTab={activeClientTypeTab}
            setActiveTab={setActiveClientTypeTab}
            defaultValues={contactInfoValues}
            onValid={handleValidStep}
          />
        )}
        {activeStep.stepKey === OrderStepKey.GROUP_LEAD && (
          <OrderGroupLeadStepForm
            defaultValues={groupLeadValues}
            contactInfo={contactInfoValues!}
            onValid={handleValidStep}
            onBack={handleGoBack}
          />
        )}
        {activeStep.stepKey === OrderStepKey.ACCOMMODATION && (
          <OrderAccommodationStepForm
            defaultValues={accommodationValues}
            onValid={handleValidStep}
            onBack={handleGoBack}
          />
        )}
        {activeStep.stepKey === OrderStepKey.PAYMENT && (
          <OrderPaymentStepForm
            offer={offer}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            defaultValues={paymentValues}
            onValid={handleValidStep}
            onBack={handleGoBack}
          />
        )}
      </Box>
    </Box>
  );
}

export default CreateOrderForm;
