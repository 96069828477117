import { useEffect, useState } from 'react';
import { Grid, Box, IconButton, Typography, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  useParams,
  useNavigate,
  useSearchParams,
  Link as RouterLink,
  Navigate,
} from 'react-router-dom';
import {
  StepperContainer,
  LogoElement,
  FormStep,
  PageLoader,
  Offer,
  colors,
  MobiferLogo,
  OfferTripSchemaType,
  OrderDisplayCard,
  OfferSchemaType,
} from 'remote/global';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CreateOrderForm from 'components/forms/CreateOrderForm/CreateOrderForm';
import { ArrowBack, MailOutline } from '@mui/icons-material';
import { getOfferTripByUuid } from 'utils/helpers';
import { useOffersByRefNumberQuery } from 'api/queries/offers';
import { ROUTES } from 'utils/routes';

export enum OrderStepKey {
  CLIENT_DATA = 'CLIENT_DATA',
  GROUP_LEAD = 'GROUP_LEAD',
  ACCOMMODATION = 'ACCOMMODATION',
  PAYMENT = 'PAYMENT',
}
export const orderStepArray = Object.values(OrderStepKey);

export interface FormStepV2<T> extends FormStep {
  stepKey: T;
  stepIndex: number;
  visible: boolean;
}

export type OrderFormStep = FormStepV2<OrderStepKey>;

type CreateOrderViewContainerProps = {
  steps: OrderFormStep[];
  activeStep: OrderFormStep;
  offers: OfferSchemaType;
  currentTrip?: OfferTripSchemaType;
  children: React.ReactNode;
};

export function ReturnHomeLink() {
  const { t } = useTranslation();
  return (
    <Link component={RouterLink} to='/' sx={{ color: colors.primary, fontSize: '14px' }}>
      <ArrowBack sx={{ verticalAlign: 'middle', mr: 2 }} fontSize='small' />
      {t('actions.fetch.goHome')}
    </Link>
  );
}

function CreateOrderViewContainer({
  steps,
  activeStep,
  offers,
  currentTrip,
  children,
}: CreateOrderViewContainerProps) {
  const tInstance = useTranslation();
  const { t } = tInstance;
  const { uuid } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <>
      <Box
        padding='16px'
        sx={{
          display: { xs: 'flex', md: 'none' },
          justifyContent: 'center',
          width: '100%',
          zIndex: 1000,
          backgroundColor: 'white',
          position: 'fixed',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            paddingBottom: '16px',
            cursor: 'pointer',
            borderBottom: `1px solid ${colors.gray12}`,
          }}
          onClick={() => navigate('/')}
        >
          <MobiferLogo env={window.MOBIFER_ENV} />
        </Box>
        <Box
          sx={{
            display: { xs: 'flex', md: 'none' },
            justifyContent: 'space-between',
          }}
        >
          <IconButton
            onClick={() => navigate(-1)}
            sx={{
              fontSize: '16px',
              lineHeight: '24px',
              display: 'flex',
              alignItems: 'center',
              color: colors.primary,
            }}
          >
            <ArrowBack sx={{ marginRight: '12px' }} /> {t('back')}
          </IconButton>
          <Typography
            sx={{
              fontSize: '16px',
              lineHeight: '24px',
              display: 'flex',
              alignItems: 'center',
              color: colors.purple,
            }}
          >
            <MailOutline sx={{ marginRight: '12px' }} /> hi@mobifer.com
          </Typography>
        </Box>
      </Box>
      <StepperContainer
        maxWidth='100%'
        steps={steps}
        activeStep={activeStep.stepIndex}
        env={window.MOBIFER_ENV}
        hideStepper={mediumScreen}
      >
        <Grid
          container
          xs={12}
          px={1}
          gap={{ xs: 5, md: 0 }}
          spacing={{ md: 5 }}
          maxWidth='lg'
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column-reverse', md: 'row' },
            mt: { xs: 18, md: 15 },
            mb: 12,
            alignItems: {
              xs: 'center',
              md: 'flex-start',
            },
            width: '100%',
            height: '100%',
            flex: 1,
          }}
        >
          <Grid container item xs={12} md={6} lg={7}>
            {children}
          </Grid>
          {currentTrip != null && (
            <Grid item xs={12} md={6} lg={5}>
              <OrderDisplayCard offers={offers} currentTrip={currentTrip} uuid={uuid!} />
            </Grid>
          )}
        </Grid>
        <LogoElement
          color='blue'
          sx={{
            position: 'fixed',
            right: '-125px',
            bottom: 0,
          }}
        />
      </StepperContainer>
    </>
  );
}

function CreateOrder() {
  const { refNumber, uuid } = useParams();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const retryPayment = searchParams.get('retryPayment');
  const { data: offers, isError, isFetching, isSuccess } = useOffersByRefNumberQuery(refNumber);
  const { trip, offer } = getOfferTripByUuid(offers, uuid!) ?? {};
  const isCurrentOfferOvernight = trip?.overnight ?? false;

  const steps: OrderFormStep[] = [
    {
      stepKey: OrderStepKey.CLIENT_DATA,
      stepIndex: orderStepArray.indexOf(OrderStepKey.CLIENT_DATA),
      label: t('OrderView.steps.clientDetails.title'),
      description: '',
      visible: true,
    },
    {
      stepKey: OrderStepKey.GROUP_LEAD,
      stepIndex: orderStepArray.indexOf(OrderStepKey.GROUP_LEAD),
      label: t('OrderView.steps.groupLead.title'),
      description: t('OrderView.steps.groupLead.subtitle'),
      visible: true,
    },
    {
      stepKey: OrderStepKey.ACCOMMODATION,
      stepIndex: orderStepArray.indexOf(OrderStepKey.ACCOMMODATION),
      label: t('OrderView.steps.accommodation.title'),
      description: t('OrderView.steps.accommodation.subtitle'),
      visible: isCurrentOfferOvernight,
    },
    {
      stepKey: OrderStepKey.PAYMENT,
      stepIndex: orderStepArray.indexOf(OrderStepKey.PAYMENT),
      label: t('OrderView.steps.payment.title'),
      description: '',
      visible: true,
    },
  ].filter((el) => el.visible);

  const [activeStep, setActiveStep] = useState<OrderFormStep>(steps[0]);

  // This useEffect will handle moving the customer back to the payment step when they get redirected from returnUrl
  const paymentStep = steps.find((s) => s.stepKey === OrderStepKey.PAYMENT)!;
  useEffect(() => {
    if (retryPayment != null && uuid != null && uuid.length > 0) {
      setActiveStep(paymentStep);
      // Remove the 'retryPayment' search term
      searchParams.delete('retryPayment');
      setSearchParams(searchParams);
    }
  }, [retryPayment, uuid, searchParams, setSearchParams, paymentStep]);

  if (isFetching)
    return (
      <CreateOrderViewContainer
        steps={steps}
        activeStep={activeStep}
        offers={offers!}
        currentTrip={trip}
      >
        <PageLoader />
      </CreateOrderViewContainer>
    );

  // Redirect user when no order found
  if (isError) {
    return <Navigate to={ROUTES.BaseRoutes.ROOT} replace />;
  }
  if (!isSuccess) return null;

  return (
    <CreateOrderViewContainer
      steps={steps}
      activeStep={activeStep}
      offers={offers}
      currentTrip={trip}
    >
      <CreateOrderForm
        offer={offer as unknown as Offer} // TODO: Fix typing
        defaultValues={[
          {
            client: offers?.client,
            // TODO: Fix type
            // @ts-ignore-next-line
            isBillingEntitySameAsDefault: offers?.billingEntity == null,
            ...(offers.billingEntity != null && { billingEntity: offers.billingEntity }),
            eInvoiceEnabled: offers.eInvoiceEnabled,
          },
          {
            groupLead: offers?.groupLead as any,
            signText: offers?.signText,
            pickUpLocationMessage: offers?.pickUpLocationMessage,
          },
          { driverAccommodationType: offers?.driverAccommodationType },
        ]}
        activeStep={activeStep}
        steps={steps}
        setActiveStep={setActiveStep}
      />
    </CreateOrderViewContainer>
  );
}

export default CreateOrder;
