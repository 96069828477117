import dayjs from 'dayjs';
import { isAdminRole, isB2bRole, isOperatorRole } from 'remote/global';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { CookieKeyEnum, MobiferProjectEnum } from './enums';
import { isFBQInitialized, isGTMInitialized } from './tracking';
import { UserData } from './data-types';
import { isDev, isLocal, isProd } from './helpers';

const getProjectDomain = (project: MobiferProjectEnum) => {
  const isLocalhost = window.location.origin.includes('http://localhost');

  // We do not set the domain for 'localhost'
  if (isLocal || isLocalhost) return undefined;

  switch (project) {
    case MobiferProjectEnum.BUSINESS: {
      if (isDev) return '.dev.mobifer.com';
      if (isProd) return '.mobifer.com';
      break;
    }
    case MobiferProjectEnum.OPERATOR: {
      if (isDev) return 'operator.dev.mobifer.com';
      if (isProd) return 'operator.mobifer.com';
      break;
    }
    case MobiferProjectEnum.SUPERADMIN: {
      if (isDev) return 'admin.dev.mobifer.com';
      if (isProd) return 'admin.mobifer.com';
      break;
    }
    default: {
      return undefined;
    }
  }

  return undefined;
};

const setCookieWithDomain = ({
  key,
  value,
  project,
  expiry,
}: {
  key: CookieKeyEnum;
  value: string;
  project: MobiferProjectEnum;
  expiry?: number;
}) => {
  const domain = getProjectDomain(project);

  Cookies.set(key, value, {
    ...(expiry != null && { expires: new Date(expiry) }),
    ...(domain != null && { domain }),
    path: '/',
  });
};

const removeCookieWithDomain = ({
  key,
  project,
}: {
  key: CookieKeyEnum;
  project: MobiferProjectEnum;
}) => {
  const domain = getProjectDomain(project);

  Cookies.remove(key, {
    ...(domain != null && { domain }),
    path: '/',
  });
};

export const setCookies = async (input: { jwt: string; refreshToken: string }) => {
  // First we have to decode the JWT to get the user role and assign the cookie based on that role
  try {
    const decodedJwt: UserData = await jwtDecode(input.jwt);
    if (decodedJwt != null) {
      const role = decodedJwt['husky.roles'];
      const jwtExpiry = decodedJwt.exp * 1000;
      const refreshExpiry = dayjs().add(1, 'month').unix() * 1000;

      if (isAdminRole(role)) {
        setCookieWithDomain({
          key: CookieKeyEnum.JWT_TOKEN,
          value: input.jwt,
          project: MobiferProjectEnum.SUPERADMIN,
          expiry: jwtExpiry,
        });
        setCookieWithDomain({
          key: CookieKeyEnum.REFRESH_TOKEN,
          value: input.refreshToken,
          project: MobiferProjectEnum.SUPERADMIN,
          expiry: refreshExpiry,
        });
      } else if (isB2bRole(role)) {
        setCookieWithDomain({
          key: CookieKeyEnum.JWT_TOKEN_BUSINESS,
          value: input.jwt,
          project: MobiferProjectEnum.BUSINESS,
          expiry: jwtExpiry,
        });
        setCookieWithDomain({
          key: CookieKeyEnum.REFRESH_TOKEN_BUSINESS,
          value: input.refreshToken,
          project: MobiferProjectEnum.BUSINESS,
          expiry: refreshExpiry,
        });
      } else if (isOperatorRole(role)) {
        setCookieWithDomain({
          key: CookieKeyEnum.JWT_TOKEN,
          value: input.jwt,
          project: MobiferProjectEnum.OPERATOR,
          expiry: jwtExpiry,
        });
        setCookieWithDomain({
          key: CookieKeyEnum.REFRESH_TOKEN,
          value: input.refreshToken,
          project: MobiferProjectEnum.OPERATOR,
          expiry: refreshExpiry,
        });
      } else {
        // TODO: This needs to be changed when there is actual authentication for landing
      }
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};

export const clearCookies = () => {
  // We will force to delete B2B tokens
  removeCookieWithDomain({
    key: CookieKeyEnum.JWT_TOKEN_BUSINESS,
    project: MobiferProjectEnum.BUSINESS,
  });
  removeCookieWithDomain({
    key: CookieKeyEnum.REFRESH_TOKEN_BUSINESS,
    project: MobiferProjectEnum.BUSINESS,
  });
};

export const grantGTMConsent = () => {
  if (isGTMInitialized) {
    window.gtag('consent', 'update', {
      ad_user_data: 'granted',
      ad_personalization: 'granted',
      ad_storage: 'granted',
      analytics_storage: 'granted',
    });
  }
};
export const grantFBQConsent = () => {
  if (isFBQInitialized) {
    window.fbq('consent', 'grant');
  }
};
