import { useMutation } from '@tanstack/react-query';
import { postRatings } from 'api/requests/requests';
import { AxiosError } from 'axios';
import { RatingSchemaType } from 'remote/global';

export const ratingsQueryKeys = {
  all: ['ratings'] as const,
};

export function usePostRatingsMutation() {
  return useMutation<number, AxiosError, RatingSchemaType>({
    mutationKey: ratingsQueryKeys.all,
    mutationFn: (v) => postRatings(v).then((d) => d.data),
  });
}
