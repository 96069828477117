import { Box, Typography, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useState, useRef } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StarOutline, Star } from '@mui/icons-material/';
import { zodResolver } from '@hookform/resolvers/zod';
import { usePostRatingsMutation } from '@/api/queries/ratings';
import {
  EditOrderCardContainer,
  colors,
  RatingSchemaType,
  ratingSchema,
  ControlledField,
  TextareaInputField,
  RatingTypeEnum,
} from 'remote/global';

type ScoresType = Record<keyof typeof RatingTypeEnum, number>;

const RATING_STAR_VALUES = [1, 2, 3, 4, 5];

function RatingsView() {
  const { token } = useParams();
  const tInstance = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = tInstance;
  const [hoveredStars, setHoveredStars] = useState<{ [key: string]: number | null }>({});
  const postRatingsMutation = usePostRatingsMutation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const ratingParam = searchParams.get('rating');

  const scoresObj = Object.values(RatingTypeEnum).reduce((acc, key) => {
    if (Number(ratingParam) > 0 && Number(ratingParam) < 6) {
      acc[key] = Number(ratingParam) || 0;
      return acc;
    }

    acc[key] = 0;
    return acc;
  }, {} as ScoresType);

  const formInstance = useForm<RatingSchemaType>({
    defaultValues: {
      comment: '',
      scores: scoresObj,
      token,
    },
    resolver: zodResolver(ratingSchema),
  });
  const { handleSubmit, watch, setValue, control } = formInstance;
  const scores = watch('scores');

  const onValid = async (data: RatingSchemaType) => {
    try {
      await postRatingsMutation.mutateAsync(data);
      navigate(`/rating-success`, {
        replace: true,
      });
    } catch (err) {
      // TODO: Capture sentry error
    }
  };

  const handleMouseOut = (event: any) => {
    if (!containerRef?.current) return;

    if (!containerRef.current.contains(event.relatedTarget)) {
      setHoveredStars({});
    }
  };

  const generateRatingText = (
    ratings: Partial<Record<keyof typeof RatingTypeEnum, number>>,
    translationPrefix: string
  ): Record<keyof typeof RatingTypeEnum, string> => {
    return Object.keys(ratings).reduce((acc, key) => {
      const rating = ratings[key as keyof typeof RatingTypeEnum];
      if (rating) {
        acc[key as keyof typeof RatingTypeEnum] = `${t(
          `${translationPrefix}.${key}.${rating}`
        )} (${rating}/5)`;
      }
      return acc;
    }, {} as Record<keyof typeof RatingTypeEnum, string>);
  };

  const hoveredRatingText = generateRatingText(hoveredStars, 'RatingsView');
  const unhoveredRatingText = generateRatingText(scores, 'RatingsView');

  const getRatingText = (key: keyof typeof RatingTypeEnum): string => {
    if (scores[key] === 0 && !hoveredStars[key]) {
      return `${t('RatingsView.noRating')} (0/5)`;
    }
    return hoveredStars[key] ? hoveredRatingText[key] : unhoveredRatingText[key];
  };

  return (
    <form id='feedbackContainer' onSubmit={handleSubmit(onValid)}>
      <EditOrderCardContainer>
        <Box
          display='flex'
          justifyContent='center'
          flexDirection='column'
          sx={{
            margin: '0 auto',
            width: '100%',
            maxWidth: '920px',
          }}
        >
          <Typography
            sx={{
              color: colors.primary,
              fontWeight: 600,
              fontSize: '30px',
              textAlign: 'center',
              lineHeight: '2rem',
              mb: '3rem',
            }}
          >
            {t('RatingsView.title')}
          </Typography>
          {Object.keys(scores).map((score) => {
            const key = score as RatingTypeEnum;
            return (
              <Box
                key={key}
                display='flex'
                justifyContent='space-between'
                sx={{
                  padding: '26px 0',
                  borderBottom: `1px solid ${colors.tiffanyBlue4}`,
                  flexDirection: {
                    xs: 'column',
                    md: 'row',
                  },
                }}
              >
                <Typography
                  sx={{
                    width: {
                      xs: '100%',
                      md: '50%',
                    },
                    color: colors.gray9,
                    fontWeight: 500,
                    fontSize: '14px',
                    textAlign: {
                      xs: 'center',
                      md: 'left',
                    },
                    lineHeight: '1.2rem',
                  }}
                >
                  {t(`RatingsView.${key}`)}
                </Typography>
                <Box
                  display='flex'
                  flexDirection='column'
                  ref={containerRef}
                  onMouseOut={handleMouseOut}
                  sx={{
                    textAlign: {
                      xs: 'center',
                      md: 'right',
                    },
                    width: {
                      xs: '100%',
                      md: '50%',
                    },
                    minWidth: '205px',
                    alignItems: {
                      xs: 'center',
                      md: 'flex-end',
                    },
                    mt: {
                      xs: '1rem',
                      md: 0,
                    },
                  }}
                >
                  <Box display='flex' justifyContent='flex-end' flexDirection='row'>
                    {RATING_STAR_VALUES.map((star) => (
                      <Box
                        key={star}
                        sx={{
                          cursor: 'pointer',
                          lineHeight: 0,
                          color: colors.restYellow,
                        }}
                        onMouseOver={() => {
                          setHoveredStars((prev) => ({ ...prev, [key]: star }));
                        }}
                        onClick={() => {
                          setValue(`scores.${key as RatingTypeEnum}`, star);
                        }}
                      >
                        {star <= (hoveredStars[key] ?? scores[key]) ? (
                          <Star
                            sx={{
                              fontSize: '1.7rem',
                            }}
                          />
                        ) : (
                          <StarOutline
                            sx={{
                              fontSize: '1.7rem',
                            }}
                          />
                        )}
                      </Box>
                    ))}
                  </Box>
                  <Typography
                    sx={{
                      color: colors.gray3,
                      fontWeight: 500,
                      fontSize: '12px',
                      textAlign: 'right',
                      lineHeight: '18px',
                    }}
                  >
                    {getRatingText(key)}
                  </Typography>
                </Box>
              </Box>
            );
          })}
          <Box
            sx={{
              mt: '28px',
              '& .MuiFormLabel-root': {
                whiteSpace: 'normal',
              },
            }}
          >
            <ControlledField
              control={control}
              name='comment'
              render={(renderProps) => (
                <TextareaInputField
                  {...renderProps}
                  fieldProps={{
                    minRows: 3,
                  }}
                />
              )}
            />
          </Box>
          <Button
            type='submit'
            variant='contained'
            sx={{
              minWidth: '155px',
              m: '2rem 0 0.5rem',
              alignSelf: 'center',
            }}
          >
            {t('submit')}
          </Button>
        </Box>
      </EditOrderCardContainer>
    </form>
  );
}

export default RatingsView;
