import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  deleteOrderByBookingNumber,
  deleteOrdersFilesByFileName,
  fetchOrdersByBookingNumber,
  fetchOrdersFiles,
  fetchOrdersOperatorTokenByAccessToken,
  fetchOrdersOperatorTokenDriverCandidates,
  postOrders,
  postOrdersFiles,
  postOrdersOperatorToken,
  patchOrdersDetails,
  postValidateOrder,
  postOrdersPaymentStart,
  fetchOrdersPaymentStatus,
} from 'api/requests/requests';
import { DEFAULT_REQUEST_INTERVAL_IN_MS } from '@/components/OffersInquiry';
import {
  StartPaymentResponseSchemaType,
  StartPaymentRequestSchemaType,
  OrderPublicDtoSchemaType,
  OrderValidationResultType,
} from 'remote/global';
import { STALE_FIVE_MINUTES } from 'api/types';
import { AxiosError } from 'axios';
import { EditOrderRequestSchemaType } from 'components/forms/OrderDriverForm/types';
import {
  ClientData,
  FileResponse,
  OrderRequestPayload,
  EditOrderPaymentStatusEnum,
} from 'utils/data-types';

export const ordersQueryKeys = {
  all: ['orders'] as const,
  detail: (bookingNumber: string, uuid?: string) =>
    [...ordersQueryKeys.all, bookingNumber, uuid] as const,
  ordersFiles: (bookingNumber: string) =>
    [...ordersQueryKeys.detail(bookingNumber), 'files'] as const,
  ordersDetails: (bookingNumber: string) =>
    [...ordersQueryKeys.detail(bookingNumber), 'details'] as const,
  orderValidation: (bookingNumber: string) =>
    [...ordersQueryKeys.detail(bookingNumber), 'validation'] as const,
  ordersOperatorToken: (accessToken: string) =>
    [...ordersQueryKeys.all, 'operator-token', accessToken] as const,
  ordersOperatorTokenDriverCandidates: (accessToken: string) =>
    [...ordersQueryKeys.ordersOperatorToken(accessToken), 'driver-candidates'] as const,
  paymentStart: (bookingNumber: string) =>
    [...ordersQueryKeys.detail(bookingNumber), 'payment', 'start'] as const,
  paymentStatus: (bookingNumber: string, uuid: string) =>
    [...ordersQueryKeys.detail(bookingNumber, uuid), 'payment', 'status'] as const,
};

export function useOrdersByBookingNumberQuery(bookingNumber?: string) {
  return useQuery({
    queryKey: ordersQueryKeys.detail(bookingNumber!),
    queryFn: () => fetchOrdersByBookingNumber(bookingNumber!),
    enabled: bookingNumber != null,
    staleTime: STALE_FIVE_MINUTES,
    select: ({ data }) => data,
  });
}

export function usePostOrdersMutation() {
  return useMutation<OrderPublicDtoSchemaType, AxiosError, OrderRequestPayload>({
    mutationKey: ordersQueryKeys.all,
    mutationFn: (data) => postOrders(data).then((d) => d.data),
  });
}

export function useOrdersFilesQuery(bookingNumber?: string) {
  return useQuery({
    queryKey: ordersQueryKeys.ordersFiles(bookingNumber!),
    queryFn: () => fetchOrdersFiles(bookingNumber!),
    staleTime: STALE_FIVE_MINUTES,
    enabled: bookingNumber != null,
    select: ({ data }) => data,
  });
}

export function useDeleteOrdersFilesMutation(bookingNumber: string) {
  const queryClient = useQueryClient();

  return useMutation<number, AxiosError, { fileName: string }>({
    mutationKey: ordersQueryKeys.ordersFiles(bookingNumber),
    mutationFn: (data) =>
      deleteOrdersFilesByFileName(bookingNumber, data.fileName).then((d) => d.status),
    onSuccess: () => {
      // Invalidate orders files
      queryClient.invalidateQueries({ queryKey: ordersQueryKeys.ordersFiles(bookingNumber) });
    },
  });
}

export function usePostOrdersFilesMutation(bookingNumber: string) {
  const queryClient = useQueryClient();

  return useMutation<FileResponse, AxiosError, FormData>({
    mutationKey: ordersQueryKeys.ordersFiles(bookingNumber),
    mutationFn: (data) => postOrdersFiles(bookingNumber, data).then((d) => d.data),
    onSuccess: () => {
      // Invalidate orders files
      queryClient.invalidateQueries({ queryKey: ordersQueryKeys.ordersFiles(bookingNumber) });
    },
  });
}

export function usePutOrdersDetailsMutation(bookingNumber: string) {
  const queryClient = useQueryClient();

  return useMutation<OrderPublicDtoSchemaType, AxiosError, ClientData>({
    mutationKey: ordersQueryKeys.ordersDetails(bookingNumber),
    mutationFn: (data) => patchOrdersDetails(bookingNumber, data).then((d) => d.data),
    onSuccess: () => {
      // Invalidate order
      queryClient.invalidateQueries({ queryKey: ordersQueryKeys.detail(bookingNumber) });
    },
  });
}

export function usePostValidateOrder(bookingNumber: string) {
  const queryClient = useQueryClient();

  return useMutation<OrderValidationResultType, AxiosError, ClientData>({
    mutationKey: ordersQueryKeys.orderValidation(bookingNumber),
    mutationFn: (data) => postValidateOrder(bookingNumber, data).then((d) => d.data),
    onSuccess: () => {
      // Invalidate result
      queryClient.invalidateQueries({ queryKey: ordersQueryKeys.orderValidation(bookingNumber) });
    },
  });
}

export function useDeleteOrderMutation(bookingNumber: string) {
  const queryClient = useQueryClient();

  return useMutation<number, AxiosError>({
    mutationKey: ordersQueryKeys.detail(bookingNumber),
    mutationFn: () => deleteOrderByBookingNumber(bookingNumber).then((d) => d.status),
    onSuccess: () => {
      // Invalidate order
      queryClient.invalidateQueries({ queryKey: ordersQueryKeys.detail(bookingNumber) });
    },
  });
}

export function useOrdersOperatorTokenQuery(accessToken?: string) {
  return useQuery({
    queryKey: ordersQueryKeys.ordersOperatorToken(accessToken!),
    queryFn: () => fetchOrdersOperatorTokenByAccessToken(accessToken!),
    staleTime: STALE_FIVE_MINUTES,
    enabled: accessToken != null,
    select: ({ data }) => data,
  });
}

export function useOrdersOperatorTokenDriverCandidatesQuery(accessToken?: string) {
  return useQuery({
    queryKey: ordersQueryKeys.ordersOperatorTokenDriverCandidates(accessToken!),
    queryFn: () => fetchOrdersOperatorTokenDriverCandidates(accessToken!),
    staleTime: STALE_FIVE_MINUTES,
    enabled: accessToken != null,
    select: ({ data }) => data,
  });
}

export function usePostOrdersPaymentStartMutation(bookingNumber: string) {
  return useMutation<StartPaymentResponseSchemaType, AxiosError, StartPaymentRequestSchemaType>({
    mutationKey: ordersQueryKeys.paymentStart(bookingNumber),
    mutationFn: (v) => postOrdersPaymentStart(bookingNumber, v).then((d) => d.data),
  });
}

export function useOrdersPaymentStatusQuery(bookingNumber: string, uuid: string) {
  return useQuery({
    queryKey: ordersQueryKeys.paymentStatus(bookingNumber, uuid),
    queryFn: () => fetchOrdersPaymentStatus(bookingNumber, uuid),
    enabled: bookingNumber != null,
    staleTime: STALE_FIVE_MINUTES,
    select: ({ data }) => data,
    // We refetch this query until the condition is met
    refetchInterval: (query) => {
      const status = query.state.data?.data ?? {};
      if (status === EditOrderPaymentStatusEnum.INITIALIZED) {
        return DEFAULT_REQUEST_INTERVAL_IN_MS;
      }

      return false;
    },
  });
}

export function usePostOrdersOperatorTokenMutation(accessToken: string) {
  return useMutation<OrderPublicDtoSchemaType, AxiosError, EditOrderRequestSchemaType>({
    mutationKey: ordersQueryKeys.ordersOperatorToken(accessToken),
    mutationFn: (v) => postOrdersOperatorToken(accessToken, v).then((d) => d.data),
  });
}
