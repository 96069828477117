import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Grid } from '@mui/material';
import { captureException } from '@sentry/react';
import { useBlockedAreasPickUpSpotsMutation } from 'api/queries/blocked-areas';
import { useMapsGoogleConfigurationQuery, useOffersSettingsQuery } from 'api/queries/config';
import { useTripsItineraryCalculatorMutation } from 'api/queries/trips';
import {
  DEFAULT_MAX_PASSENGER_COUNT,
  PassengerCountField,
  RideLegSchemaType,
  RideOfferType,
  encodeRideLegs,
  multiStopLegsSchema,
  passengerCountWithMaxSchemaObject,
  unpackDefaultRideLegs,
  MultiStopRideSchemaType,
  RideDetailsSchemaType,
  multiStopRideSchema,
  zo,
  TravelTypeTabs,
  emptyRideLegObject,
  MultiStopLocationField,
  MultiStopLocationFieldOld,
  TimeTypeEnum,
} from 'remote/global';
import { isProd } from '@/utils/helpers';
import { isEqual } from 'lodash-es';
import { useCallback } from 'react';
import { useForm, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type ItineraryResponse = {
  adjusted: boolean;
  legs: RideLegSchemaType[];
};

const emptyLegArray = Array.from({ length: 3 }, () => emptyRideLegObject);
const makeRideLegs = (data?: RideDetailsSchemaType) => {
  const legs = (data as MultiStopRideSchemaType)?.legs;
  // This only works in one direction, when a user selects "multi-stop" without previously assigning values to the form
  if (legs == null || !Array.isArray(legs) || legs.length <= 0) {
    // Check if there is startLocation or endLocation and attach those to legs
    return [
      {
        ...emptyRideLegObject,
        startLocation: (data as any)?.startLocation ?? null,
        startTime: (data as any)?.startTime ?? null,
        arrivalTime: (data as any)?.arrivalTime ?? null,
      },
      emptyRideLegObject,
      {
        ...emptyRideLegObject,
        startLocation: (data as any)?.endLocation ?? null,
      },
    ];
  }

  const isEveryLegEmpty = legs.every((leg) => isEqual(leg, emptyRideLegObject));
  if (isEveryLegEmpty) return emptyLegArray;

  if (legs[legs.length - 1].endLocation == null) {
    return legs;
  }

  const correctedLegs = [
    ...legs,
    {
      ...emptyRideLegObject,
      startLocation: legs[legs.length - 1].endLocation,
    },
  ];

  return correctedLegs;
};

function VehicleMultiStopStepForm({
  defaultValues,
  onValid,
  setFormResult,
  isCounterRunning,
  handleDateTimeAccept,
}: {
  defaultValues?: RideDetailsSchemaType;
  isCounterRunning?: boolean;
  onValid: (data: MultiStopRideSchemaType) => void;
  setFormResult: React.Dispatch<React.SetStateAction<RideDetailsSchemaType | undefined>>;
  handleDateTimeAccept: (val: FieldValues | null) => void;
}) {
  const { data: googleConfig } = useMapsGoogleConfigurationQuery();
  const { data: offersSettings } = useOffersSettingsQuery();
  const tInstance = useTranslation();
  const { t } = tInstance;
  const formInstance = useForm<MultiStopRideSchemaType>({
    defaultValues: {
      maxPassengerCount: DEFAULT_MAX_PASSENGER_COUNT,
      passengerCount: '' as any,
      ...defaultValues,
      activeTab: TimeTypeEnum.LEAVING,
      legs: makeRideLegs(defaultValues),
      type: RideOfferType.MULTI_STOP,
    },
    resolver: zodResolver(multiStopRideSchema),
  });
  const {
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { isSubmitting, isValidating, isLoading },
  } = formInstance;
  const pickupSpotsMutation = useBlockedAreasPickUpSpotsMutation();
  const { mutateAsync: tripsItineraryMutateAsync } = useTripsItineraryCalculatorMutation();

  const handleTriggerRouteTimes = useCallback(async () => {
    const data = getValues();
    const { passengerCount, maxPassengerCount, legs } = data;
    // Parse the passengerCount to validate if a request should be made
    try {
      await passengerCountWithMaxSchemaObject.parseAsync({ passengerCount, maxPassengerCount });
    } catch (e) {
      // We do not set errors for passengerCount because they are handled internally (onChange)
      return; // does not allow to continue
    }

    const breakTimes = legs.map((leg) => leg.breakTime);
    // Parse the legs to validate if a request should be made
    try {
      await multiStopLegsSchema.parseAsync(legs);
      clearErrors('legs'); // makes sure to clear the errors as catch keeps them
    } catch (err) {
      const isEveryLocationFilled = legs.every((leg) => leg.startLocation != null);
      if (!legs[0].startTime || !legs[1].arrivalTime) return;

      const isFirstStartTimeFilled =
        data.activeTab === TimeTypeEnum.LEAVING && legs[0].startTime?.length > 0;

      const isSecondStartTimeFilled =
        data.activeTab === TimeTypeEnum.ARRIVING && legs[1].arrivalTime?.length > 0;

      // Only show errors when certain conditions are met
      const showErrors =
        isEveryLocationFilled && (isFirstStartTimeFilled || isSecondStartTimeFilled);

      if (showErrors && err instanceof zo.ZodError)
        err.issues.forEach(({ path, message, code }) => {
          setError(
            `legs.${Array.isArray(path) ? path.join('.') : path}` as any,
            { type: code, message },
            { shouldFocus: true }
          );
        });
      return; // does not allow to continue
    }

    try {
      // We have to format legs before sending them to BE, because BE expects a different kind of input
      const response = await tripsItineraryMutateAsync({
        legs: encodeRideLegs(legs, data.activeTab === TimeTypeEnum.ARRIVING),
        passengerCount,
        rejectFerryTrip: true,
      });
      const itineraryLegs = (response as ItineraryResponse)?.legs ?? []; // TODO: Fix this typing

      // Sets the correct legs from BE response
      if (itineraryLegs.length > 0) {
        if (data.activeTab === TimeTypeEnum.ARRIVING && !isProd) {
          const unpackedLegs = unpackDefaultRideLegs(itineraryLegs);
          const mappedLegs = unpackedLegs?.map((leg, index) => ({
            ...leg,
            arrivalTime: leg.endTime || null,
            breakTime: breakTimes[index],
          }));
          setValue('legs', mappedLegs!);
          return;
        }
        setValue('legs', unpackDefaultRideLegs(itineraryLegs)!);
      }
    } catch (e) {
      captureException(e);
    }
  }, [getValues, setValue, setError, clearErrors, tripsItineraryMutateAsync]);

  return (
    <form onSubmit={handleSubmit(onValid)}>
      <Grid item xs={12}>
        {/* TODO: Create new TravelTypeTabs component that adheres to control of useForm */}
        <TravelTypeTabs
          label={t('FormFields.rideType.label') as string}
          value={RideOfferType.MULTI_STOP}
          options={[RideOfferType.ONE_WAY, RideOfferType.ROUND_TRIP, RideOfferType.MULTI_STOP]}
          handleChange={(value) => {
            const activeTab = getValues('activeTab');
            setFormResult({
              ...getValues(),
              type: value as RideOfferType.MULTI_STOP,
              activeTab,
            }); // Sets the form defaultValues as the current forms values
          }}
        />
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={12} sm={1.5}>
              <PassengerCountField
                form={formInstance}
                handleTriggerRouteTimes={handleTriggerRouteTimes}
              />
            </Grid>
            <Grid item xs={12} sm={9}>
              {isProd ? (
                <MultiStopLocationFieldOld
                  formInstance={formInstance}
                  googleConf={googleConfig!}
                  // TODO: Fix typing
                  offersSettings={offersSettings as any}
                  getPickupSpots={(coords) =>
                    pickupSpotsMutation.mutateAsync({
                      lat: String(coords.lat),
                      lon: String(coords.lng),
                    })
                  }
                  fieldProps={{
                    onAccept: (val) => {
                      handleDateTimeAccept(val);
                      handleTriggerRouteTimes();
                    },
                  }}
                  handleTriggerRouteTimes={handleTriggerRouteTimes}
                />
              ) : (
                <MultiStopLocationField
                  formInstance={formInstance}
                  googleConf={googleConfig!}
                  // TODO: Fix typing
                  offersSettings={offersSettings as any}
                  getPickupSpots={(coords) =>
                    pickupSpotsMutation.mutateAsync({
                      lat: String(coords.lat),
                      lon: String(coords.lng),
                    })
                  }
                  fieldProps={{
                    onAccept: (val) => {
                      handleDateTimeAccept(val);
                      handleTriggerRouteTimes();
                    },
                  }}
                  handleTriggerRouteTimes={handleTriggerRouteTimes}
                />
              )}
            </Grid>
            <Grid item xs={12} sm={1.5} mt='calc(2rem - 1px)'>
              <Button
                sx={{
                  display: 'flex',
                  ml: ['auto', 'unset'],
                  width: { sm: '100%' },
                }}
                type='submit'
                variant='contained'
                color='primary'
                disableElevation
                disabled={isCounterRunning || isSubmitting || isValidating || isLoading}
              >
                {t('continue')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

export default VehicleMultiStopStepForm;
