import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, InputAdornment, Typography } from '@mui/material';
import {
  useOffersAdditionalOffersEmailMutation,
  useOffersByRefNumberQuery,
} from 'api/queries/offers';
import {
  ControlledField,
  NoOffersEmailInputSchemaType,
  noOffersEmailInputSchema,
  OfferHeaderTitle,
} from 'remote/global';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { EmailOutlined } from '@mui/icons-material';
import OffersLoading from './OffersLoading';

export const DEFAULT_REQUEST_INTERVAL_IN_MS = 1000;
export const OFFER_COUNT_DURATION_IN_SECONDS = 120;

function OfferPollInquirySection({ counter }: { counter: number }) {
  const { t } = useTranslation();
  const { refNumber } = useParams();

  // Polling this will result in other queries with the same queryKey to be also updated
  useOffersByRefNumberQuery(refNumber, {
    refetchInterval: () => {
      if (counter > 0) return DEFAULT_REQUEST_INTERVAL_IN_MS;
      return false;
    },
  });

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <Box
        sx={{
          my: 6,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography sx={{ fontWeight: 700, fontSize: '48px', mb: '38px' }}>
          {t('OffersView.pending.title')}
        </Typography>
        <OffersLoading
          isDone={counter === 0}
          text={t('OffersView.pending.progress.searching', {
            seconds: counter,
          }).toString()}
        />
      </Box>
    </Box>
  );
}

function OffersUnavailableForm({
  defaultValues,
}: {
  defaultValues?: NoOffersEmailInputSchemaType;
}) {
  const tInstance = useTranslation();
  const { t, i18n } = tInstance;
  const { refNumber } = useParams();

  const formInstance = useForm<NoOffersEmailInputSchemaType>({
    defaultValues: {
      email: '',
      ...defaultValues,
    },
    resolver: zodResolver(noOffersEmailInputSchema),
  });
  const {
    handleSubmit,
    control,
    formState: { isSubmitting, isSubmitSuccessful },
  } = formInstance;
  const offersAdditionalOffersEmailMutation = useOffersAdditionalOffersEmailMutation(refNumber);
  const isEmailSent = offersAdditionalOffersEmailMutation.isSuccess;

  const onValid = async (data: NoOffersEmailInputSchemaType) => {
    await offersAdditionalOffersEmailMutation.mutateAsync({
      ...data,
      lang: i18n.language,
    });
  };

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      {!isEmailSent ? (
        <>
          <OfferHeaderTitle
            title={t('OffersView.unavailable.title')}
            subtitle={t('OffersView.unavailable.subtitle')}
          />
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: '48px', maxWidth: '342px' }}>
            <form onSubmit={handleSubmit(onValid)}>
              <ControlledField
                control={control}
                name='email'
                disabled={isSubmitting || isSubmitSuccessful}
                focusInitial
                fieldProps={{
                  InputProps: {
                    type: 'email',
                    autoComplete: 'email',
                    startAdornment: (
                      <InputAdornment position='start'>
                        <EmailOutlined />
                      </InputAdornment>
                    ),
                  },
                }}
              />
              <Button
                sx={{ mt: '24px' }}
                type='submit'
                disabled={isSubmitting || isSubmitSuccessful}
                fullWidth
                variant='contained'
              >
                {t('OffersView.actions.contactEmail')}
              </Button>
            </form>
          </Box>
        </>
      ) : (
        <OfferHeaderTitle
          title={t('OffersView.unavailable.completed.title')}
          subtitle={t('OffersView.unavailable.completed.subtitle')}
        />
      )}
    </Box>
  );
}

interface Props {
  isCounterEnabled: boolean;
  isCounterRunning: boolean;
  counter: number;
}

function OffersInquiry({ isCounterEnabled, isCounterRunning, counter }: Props) {
  const { refNumber } = useParams();

  const {
    data: offers,
    isSuccess: isOffersSuccess,
    isLoading: isOffersLoading,
  } = useOffersByRefNumberQuery(refNumber);

  if (!isOffersSuccess) return null;

  const isAnyOfferAvailable = offers.trips.flatMap((trip) => trip.operatorOffers).length > 0;
  const isNoOffersAvailable = !isCounterEnabled && !isAnyOfferAvailable;

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      {isCounterRunning && <OfferPollInquirySection counter={counter} />}
      {isNoOffersAvailable && !isOffersLoading && (
        <OffersUnavailableForm
          defaultValues={{
            email: offers.additionalOffersEmail ?? '',
          }}
        />
      )}
    </Box>
  );
}

export default OffersInquiry;
