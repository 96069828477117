import { WarningOutlined } from '@mui/icons-material';
import { colors as muiColors } from '@mui/material';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { localI18nInstance } from '../i18n';

interface MobiferErrorResponse {
  error: string;
  errorCode: string;
  path: string;
  status: number;
  timestamp: number;
}

const getErrorMessage = (errorCode: string) => {
  const key = `ErrorCode.${errorCode}`;
  const exists = localI18nInstance.exists(key);
  if (exists) return localI18nInstance.t(key);
  return errorCode;
};

const setGlobalError = (error: AxiosError) => {
  const { errorCode } = (error.response?.data as MobiferErrorResponse) ?? {};
  const isServerError = error.response?.status === 500;
  const isForbiddenError = error.response?.status === 403;
  if (errorCode != null && errorCode.length > 0) {
    toast.error(getErrorMessage(errorCode));
  } else if (isServerError) {
    toast.error(getErrorMessage('SERVER_ERROR'));
  } else if (isForbiddenError) {
    toast.error(getErrorMessage('FORBIDDEN_ERROR'));
  }
};

const DEFAULT_MAX_RETRY_COUNT = 3;
export const handleCustomRetry = (
  failureCount: number,
  error: AxiosError<MobiferErrorResponse>
) => {
  const { status } = error?.response ?? {};
  const { errorCode } = error.response?.data ?? {};
  // If the error is not 5xx, cancel retry
  if (typeof status === 'number' && status >= 400 && status < 500) return false;
  // If 'errorCode' exists, cancel retry
  if (errorCode != null) return false;
  // If failureCount exceeds default count, cancel retry
  if (failureCount >= DEFAULT_MAX_RETRY_COUNT) return false;
  return true;
};

const setGlobalSuccess = (data: any) => {
  const { warningCode } = data ?? {};
  if (warningCode != null)
    toast.error(getErrorMessage(warningCode), {
      icon: <WarningOutlined sx={{ fill: muiColors.yellow[900] }} />,
      style: { background: muiColors.yellow[500] },
      duration: 5000,
    });
};

function StableQueryClient({ children }: { children: React.ReactNode }) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: handleCustomRetry,
          },
          mutations: {
            retry: handleCustomRetry,
          },
        },
        mutationCache: new MutationCache({
          onError: (error) => {
            setGlobalError(error);
          },
          onSuccess: (data) => {
            setGlobalSuccess(data);
          },
        }),
        queryCache: new QueryCache({
          onError: (error) => {
            setGlobalError(error);
          },
          onSuccess: (data) => {
            setGlobalSuccess(data);
          },
        }),
      })
  );
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
}

export default StableQueryClient;
