import { Button, Box, Typography, Grid, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Stack } from '@mui/system';
import { zodResolver } from '@hookform/resolvers/zod';
import { useOperatorOffersApprovalMutation } from 'api/queries/operator-offers';
import {
  ControlledField,
  CheckboxInputField,
  colors,
  ContactSupportTrans,
  OfferSchemaType,
  DEFAULT_MAX_PASSENGER_COUNT,
} from 'remote/global';
import { useForm } from 'react-hook-form';
import { VehiclePublic } from 'utils/data-types';
import SuccessComponent from 'components/SuccessComponent';
import NewVehicleDetailsFields from './NewVehicleDetailsFields';
import NewOfferInfo from './NewOfferInfo';
import { acceptOperatorOfferRequestSchema, AcceptOperatorOfferRequestSchemaType } from './types';

function MakeOfferForm({
  offer,
  vehicles,
  defaultValues,
}: {
  offer: OfferSchemaType;
  vehicles: VehiclePublic[];
  defaultValues?: Partial<AcceptOperatorOfferRequestSchemaType>;
}) {
  const { token } = useParams();
  const tInstance = useTranslation();
  const { t } = tInstance;

  const operatorOffersApprovalMutation = useOperatorOffersApprovalMutation(token);
  const { isSuccess } = operatorOffersApprovalMutation;

  const formInstance = useForm<AcceptOperatorOfferRequestSchemaType>({
    defaultValues: {
      newVehicle: null,
      contactEmail: '',
      licensePlate: null,
      newPriceExclVat: '' as unknown as number,
      vehicleId: null,
      uuid: '',
      pickExistingVehicle: true,
      termsAccepted: undefined,
      minPassengerCount: offer.passengerCount,
      maxPassengerCount: DEFAULT_MAX_PASSENGER_COUNT,
      ...defaultValues,
    },
    resolver: zodResolver(acceptOperatorOfferRequestSchema),
  });
  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    formState: { isSubmitting, isValidating, isLoading },
  } = formInstance;

  const onValid = (data: AcceptOperatorOfferRequestSchemaType) =>
    operatorOffersApprovalMutation.mutate(data);

  const handleSelectVehicle = (licensePlate: string) => {
    const selectedVehicle = vehicles?.find((vehicle) => vehicle.licensePlate === licensePlate);

    if (!selectedVehicle) return;
    setValue('vehicleId', selectedVehicle.id);
    setValue('licensePlate', selectedVehicle.licensePlate);
    clearErrors('licensePlate');
  };

  if (isSuccess) {
    return (
      <SuccessComponent token={token!}>
        <Stack gap={4}>
          <Typography variant='h2' sx={{ fontWeight: 700, color: colors.primary }}>
            {t('MakeOfferView.completed.title')}
          </Typography>
          <Typography variant='subtitle2' fontSize='medium'>
            {t('MakeOfferView.completed.subtitle')}
          </Typography>
          <Typography variant='subtitle2' fontSize='medium'>
            {t('MakeOfferView.completed.description')}
          </Typography>
        </Stack>
      </SuccessComponent>
    );
  }

  return (
    <form onSubmit={handleSubmit(onValid)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          alignItems: 'center',
          padding: '0 1rem 3rem',
        }}
      >
        <Box display='flex' flexDirection='column' alignItems='center'>
          <Stack textAlign='center' my={6}>
            <Typography variant='h2' sx={{ mb: '16px', fontWeight: 700, color: colors.primary }}>
              {t('MakeOfferView.title')}
            </Typography>
            <ContactSupportTrans i18nKey='MakeOfferView.subtitle' />
          </Stack>
        </Box>
        <Grid container spacing={4} maxWidth='1200px'>
          <Grid item display='flex' flexDirection='column' xs={12} md={6}>
            <Typography variant='numberText' mb='20px' color='primary' fontWeight='600'>
              {t('MakeOfferView.vehicleDetails.title')}
            </Typography>
            <Paper
              elevation={3}
              sx={{
                flexDirection: 'column',
                display: 'flex',
                p: '30px 25px',
                mb: '12px',
                borderRadius: '16px',
              }}
            >
              <NewVehicleDetailsFields
                vehicles={vehicles}
                handleSelectVehicle={handleSelectVehicle}
                formInstance={formInstance}
              />
            </Paper>
          </Grid>
          <Grid
            xs={12}
            md={6}
            item
            display='flex'
            flexDirection='column'
            sx={{
              width: {
                xs: '100%',
                md: '420px',
              },
            }}
          >
            <Typography variant='numberText' mb='20px' color='primary' fontWeight='600'>
              {t('MakeOfferView.clientDetails.title')}
            </Typography>
            <NewOfferInfo offerData={offer} formInstance={formInstance} />
            <ControlledField
              control={control}
              name='contactEmail'
              fieldProps={{
                InputProps: {
                  type: 'email',
                  autoComplete: 'email',
                },
              }}
            />
            <Box p='20px 0'>
              <ControlledField
                control={control}
                name='termsAccepted'
                render={(renderProps) => (
                  <CheckboxInputField {...renderProps} translationKey='termsAcceptedOffer' />
                )}
              />
            </Box>
            <Button type='submit' disabled={isSubmitting || isValidating || isLoading}>
              {t('actions.user.sendOffer')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
}

export default MakeOfferForm;
