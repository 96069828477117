import { VehicleExtrasEnum, Offer, RideLegSchemaType, VehicleClass } from 'remote/global';

export type StringOrNumber = string | number;

export interface Location {
  address: string;
  countryCode: string;
  coords: {
    lat: number;
    lon: number;
  };
}

export interface LegalText {
  title: string;
  subtitle: string;
  body: {
    title: string;
    content: string[];
  }[];
}

export interface Trip {
  operatorOffers: Offer[];
  rides: Array<{
    legs: Array<{
      type: string;
      startLocation: Location;
      startTime: string;
      endLocation: Location;
      endTime: string;
      distanceKm: number;
    }>;
  }>;
}

export interface OfferLocation {
  locationText: string;
  coords: {
    lat: number;
    lon: number;
  };
  placeId?: string;
}

export interface OfferLeg {
  endLocation: OfferLocation | null;
  startLocation: OfferLocation | null;
  startTime: Date | null;
}

export interface VehiclePublic {
  id: number;
  licensePlate: string;
  make: string;
  model: string;
  seats: number;
  vehicleClass: VehicleClass;
}

export interface ItineraryLeg {
  distanceKm: number;
  endLocation: Location;
  endTime: Date;
  startLocation: Location;
  startTime: Date;
  type: string;
}

export interface Itinerary {
  adjusted: boolean;
  legs: ItineraryLeg[];
}

export interface OfferPayload {
  driverLanguage?: string;
  duration?: number;
  endLocation?: OfferLocation;
  extras: string[];
  legs: OfferLeg[];
  passengerCount: number;
  returnTime?: string;
  specialLuggageCount?: number;
  specialLuggageType?: string;
  startLocation?: OfferLocation;
  startTime?: Date;
  kidFriendly?: boolean;
  maxAge?: number;
  internalTesting?: boolean;
  type: string;
}

export interface PaymentIntent {
  amount: number;
  bookingNumber: string;
  currency: {
    currencyCode: string;
    defaultFractionDigits: number;
    displayName: string;
    numericCode: number;
    numericCodeAsString: string;
    symbol: string;
  };
  offerUuid: string;
  paymentIntentClientSecret: string;
  publishableKey: string;
  validUntil: string;
}

export enum PaymentStatusEnum {
  INITIALIZED = 'INITIALIZED',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
}

export enum EditOrderPaymentStatusEnum {
  INITIALIZED = 'INITIALIZED',
  APPLIED = 'APPLIED',
  FAILED = 'FAILED',
  EXPIRED = 'EXPIRED',
}

export interface PaymentStatus {
  amount: number;
  bookingNumber: string;
  currency: {
    currencyCode: string;
    defaultFractionDigits: number;
    displayName: string;
    numericCode: number;
    numericCodeAsString: string;
    symbol: string;
  };
  expired: boolean;
  orderCreated: boolean;
  failed: boolean;
  status: PaymentStatusEnum;
}

export interface ClientData {
  client: {
    companyEmail: string;
    companyName: string;
    companyPhone: string;
    contactInfo: {
      email: string;
      firstName: string;
      lastName: string;
      phone: string;
    };
    type: string;
  };
  clientIsGroupLead: boolean;
  groupLead: {
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
  };
  pickUpLocationMessage: string;
}

export type PickupSpot = {
  coords: {
    lat: number;
    lon: number;
  };
  name: string;
  placeId: string;
};

export interface FileResponse {
  fileName: string;
  fileSize: number;
  fileType: string;
  originalFileName: string;
  url: string;
}

export interface OrderRequestPayload {
  operatorOfferUuid: string;
}

export type GoogleConfig = {
  enabledCountries: string[];
  feApiKey: string;
};

export type OffersSettings = {
  cityHireDuration: Record<string, string>;
  maxLegs: number;
  minAdvanceTime: string;
  vehicleExtras: Partial<VehicleExtrasEnum[]>;
};

export interface UserData {
  'husky.operatorId': number;
  sub: string;
  'husky.userId': number;
  nbf: number;
  'husky.roles': string;
  'husky.clientId': number;
  exp: number;
  iat: number;
}

export type ItineraryRequest = {
  legs: RideLegSchemaType[];
  passengerCount: number;
  rejectFerryTrip: boolean;
};

export type ItineraryResponse = {
  adjusted: boolean;
  legs: RideLegSchemaType[];
  polylines: string[];
};

export type IdValueDtoLongString = {
  id: number;
  value: string;
};
